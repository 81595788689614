import type { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createCustomTheme } from './theme/theme';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router';
import routes from './routes';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import Footer from './pages/authentication/Footer';
import { FooterVisibilityProvider } from './store/FooterVisibilityContext';

const App: FC = () => {
    const content = useRoutes(routes);
    const { settings } = useSettings();

    useScrollReset();

    const theme = createCustomTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        roundedCorners: settings.roundedCorners,
        theme: settings.theme
    });

    return (
        <FooterVisibilityProvider>
            <ThemeProvider theme={ theme }>
                <CssBaseline />
                <Toaster position="top-center" />
                { content }
                <Footer />
            </ThemeProvider>
        </FooterVisibilityProvider>
    );
};

export default App;
