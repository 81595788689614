import idyAxios, { clearAccessToken } from './idyAxios';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
import { Misc } from '../utils/misc';

const USER_UUID_COOKIE_KEY = 'idy-user-uuid';
export const CLIENT_SESSION_UUID = 'X-CLIENT-SESSION-UUID';
const X_IDY_LANGUAGE = 'X-IDY-LANG';
const X_ORGANIZATION_UUID = 'X-ORGANIZATION-UUID';
const USER_PIN = 'idy-user-pin';
const REFRESH_TOKEN_COOKIE_KEY = 'refresh_token';

// keys used in web components for storing user token and refresh token
const USER_TOKEN_LS_KEY_WC = 'idy-user-token';
const REFRESH_TOKEN_LS_KEY_WC = 'refresh_token';

export default {
    clearLoginData() {
        const refreshTokenCookieKey = `${ this.getUserUuid() }_${ REFRESH_TOKEN_COOKIE_KEY }_${ Misc.getEnvironment() }`;
        const userUuidCookieKey = `${ USER_UUID_COOKIE_KEY }_${ Misc.getEnvironment() }`;

        this.cookies.remove(refreshTokenCookieKey, Misc.isLocalEnvironment() ? undefined : { path: '/', domain: 'identyum.com' });
        this.cookies.remove(userUuidCookieKey, Misc.isLocalEnvironment() ? undefined : { path: '/', domain: 'identyum.com' });

        // remove user token and refresh token from local storage - web components used them from there
        localStorage.removeItem(USER_TOKEN_LS_KEY_WC);
        localStorage.removeItem(USER_TOKEN_LS_KEY_WC + '_' + Misc.getEnvironment());
        localStorage.removeItem(REFRESH_TOKEN_LS_KEY_WC);
        localStorage.removeItem(REFRESH_TOKEN_LS_KEY_WC + '_' + Misc.getEnvironment());

        this.removePin();
        clearAccessToken();
        idyAxios.update();
    },

    cookies: new Cookies(),

    getClientSessionUuid(): string | null {
        return this.cookies.get(`${ CLIENT_SESSION_UUID }_${ Misc.getEnvironment() }`) || null;
    },

    getIdyLanguage(): string | null {
        return localStorage.getItem(X_IDY_LANGUAGE) || i18n.language;
    },

    getOrganizationUuid(): string {
        const organizationUuid = localStorage.getItem(X_ORGANIZATION_UUID);
        return organizationUuid || '';
    },

    getRefreshToken(): string | null {
        if (!this.getUserUuid() || !Misc.getEnvironment()) {
            return null;
        }

        const cookieKey = `${ this.getUserUuid() }_${ REFRESH_TOKEN_COOKIE_KEY }_${ Misc.getEnvironment() }`;
        return this.cookies.get(cookieKey) || null;
    },

    getUserPin(): string | null {
        return sessionStorage.getItem(`${ USER_PIN }_${ Misc.getEnvironment() }`);
    },

    getUserUuid(): string | null {
        return this.cookies.get(`${ USER_UUID_COOKIE_KEY }_${ Misc.getEnvironment() }`) || null;
    },

    removePin() {
        sessionStorage.removeItem(`${ USER_PIN }_${ Misc.getEnvironment() }`);
        idyAxios.update();
    },

    setClientSessionUuid(clientSessionUuid: string) {
        const clientSessionUuidKey = `${ CLIENT_SESSION_UUID }_${ Misc.getEnvironment() }`;
        this.cookies.remove(
            clientSessionUuidKey,
            Misc.isLocalEnvironment() ? { path: '/', domain: 'localhost' } : { path: '/', domain: 'identyum.com' }
        );
        this.cookies.set(
            clientSessionUuidKey,
            clientSessionUuid,
            {
                path: '/',
                domain: Misc.isLocalEnvironment() ? 'localhost' : 'identyum.com',
                expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            }
        );
        idyAxios.update();
    },

    setIdyLanguage(languageCode: string) {
        localStorage.removeItem(X_IDY_LANGUAGE);
        localStorage.setItem(X_IDY_LANGUAGE, languageCode);
        idyAxios.update();
    },

    setOrganizationUuid(organizationUuid: string) {
        localStorage.setItem(X_ORGANIZATION_UUID, organizationUuid);
        idyAxios.update();
    },

    setUserPin(userPin: string) {
        sessionStorage.setItem(`${ USER_PIN }_${ Misc.getEnvironment() }`, userPin);
        idyAxios.update();
    },

    setUserUuid(userName: string) {
        const userUuidCookieKey = USER_UUID_COOKIE_KEY + '_' + Misc.getEnvironment();
        this.cookies.remove(
            userUuidCookieKey,
            Misc.isLocalEnvironment() ? { path: '/', domain: 'localhost' } : { path: '/', domain: 'identyum.com' }
        );
        this.cookies.set(
            userUuidCookieKey,
            userName,
            {
                path: '/',
                domain: Misc.isLocalEnvironment() ? 'localhost' : 'identyum.com',
                expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            }
        );
    },

    storeRefreshToken(refreshToken: string) {
        this.cookies.set(
            `${ this.getUserUuid() }_${ REFRESH_TOKEN_COOKIE_KEY }_${ Misc.getEnvironment() }`,
            refreshToken,
            {
                path: '/',
                domain: Misc.isLocalEnvironment() ? 'localhost' : 'identyum.com',
                expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            }
        );
        idyAxios.update();
    },
};

export interface Token {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    refresh_expires_in: number;
    session_state?: string;
    token_type?: string;
    id_token?: string;
}
