import type { FC } from 'react';
import { IMenuSection } from '../../../model/common/navigation/menu-section';
import { Box, Button, Divider, Typography } from '@mui/material';
import NavSection from '../../navsection/NavSection';
import { useTranslation } from 'react-i18next';
import { AppSettings } from '../../../common/app-settings';

interface ISidebarMenu {
    menu: IMenuSection[];
}

const SidebarMenu: FC<ISidebarMenu> = ({ menu }) => {
    const { t } = useTranslation();

    return (
        <>
            <Divider />
            <Box sx={ { p: 2 } }>
                { menu.map((section: IMenuSection) => (
                    <NavSection key={ section.title } pathname={ location.pathname } { ...section } sx={ { '& + &': { mt: 3 } } } />
                )) }
            </Box>
            <Divider sx={ { p: 2 } } />
            <Box sx={ { p: 2 } }>
                <Typography color="textPrimary" variant="subtitle2">
                    { t('layout.footer.label.needHelp') }
                </Typography>
                <Typography color="textSecondary" variant="body2">
                    { t('layout.footer.label.checkDocs.label') }</Typography>
                <Button color="primary"
                        target="_blank"
                        href={ AppSettings.HELP_CENTER_LINK }
                        fullWidth
                        sx={ { mt: 2, mb: 2 } }
                        variant="contained">
                    { t('layout.footer.label.docs') }
                </Button>
            </Box>
            <Divider sx={ { p: 2 } } />
        </>
    );
};

export default SidebarMenu;
