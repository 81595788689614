import type { FC } from 'react';
import { ChangeEvent, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DocumentsHelper } from '../../utils/documents-helper';
import { ErrorType, IError } from '../../model/common/error';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import { IPhone } from '../../model/common/documents/document-user';
import CountryCodeChooser from '../CountryCodeChooser';
import { UserContactsHelper } from '../../utils/user-contacts-helper';

interface IAddPhoneNumberProps {
    existingPhones: IPhone[];
    onAddPhones: (phones: IPhone[]) => void;
    onOpenContactModal: (isOpen: boolean) => void;
}

const AddPhoneNumber: FC<IAddPhoneNumberProps> = ({ existingPhones, onAddPhones, onOpenContactModal }) => {
    const { t } = useTranslation();
    const [ phoneNumber, setPhoneNumber ] = useState<string>('');
    const [ dialCode, setDialCode ] = useState<string>('');
    const [ validationError, setValidationError ] = useState<IError>({
        error: false,
        errorMessage: '',
        errorType: ErrorType.VALIDATION_ERROR
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValidationError({ error: false, errorMessage: '', errorType: ErrorType.VALIDATION_ERROR });
        const onlyNumbers = event.target.value.replace(/[^0-9]/g, '');
        setPhoneNumber(onlyNumbers);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleAddClick();
        }
    };

    const handleDialCodeSelect = (dialCode: string) => {
        setDialCode(dialCode);
    };

    const handleAddClick = () => {
        const phoneNr = DocumentsHelper.stripLeadingZeros(phoneNumber);
        const isValidPhoneNumber = DocumentsHelper.isValidInternationalPhoneNumber(`+${ dialCode }${ phoneNr }`);
        const isAlreadyAdded = existingPhones.filter((phone) => phone?.dialCode === dialCode && phone?.number === phoneNr)?.length > 0;

        if (!isValidPhoneNumber) {
            setValidationError({
                error: true,
                errorMessage: t('receiver.error.mobile.wrongFormat') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
        }

        if (isAlreadyAdded) {
            setValidationError({
                error: true,
                errorMessage: t('receiver.error.mobile.alreadyAdded') as string,
                errorType: ErrorType.VALIDATION_ERROR
            });
            setPhoneNumber('');
        }

        if (isValidPhoneNumber && !isAlreadyAdded) {
            onAddPhones([{
                dialCode: dialCode,
                number: phoneNr
            } as IPhone]);
            setPhoneNumber('');
        }

    };

    return (
        <Grid item container direction='row' justifyContent='flex-start' alignItems='center' spacing={ 1 }>
            <Grid item xs={ 12 } sm={ 3 } md={ 3 } lg={ 3 } xl={ 3 }>
                <CountryCodeChooser
                    onSelect={ handleDialCodeSelect }
                    fallbackDialCode={ UserContactsHelper.FALLBACK_DIAL_CODE }
                    flagsEnabled={ true }
                    validationErrorMessage={ validationError.errorMessage } />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 } xl={ 6 }>
                <TextField
                    error={ Boolean(validationError.errorMessage) }
                    // helperText={Boolean(validationError.errorMessage) && validationError.errorMessage}
                    autoFocus
                    fullWidth
                    label={ t('receiver.input.mobile') }
                    onChange={ handleChange }
                    onKeyDown={ handleKeyDown }
                    placeholder={ t('receiver.input.mobile.example') }
                    rows={ 1 }
                    value={ phoneNumber }
                    variant='outlined' />
            </Grid>
            <Grid item xs={ 12 } sm={ 3 } md={ 3 } lg={ 3 } xl={ 3 }>
                <CommonButton btnType={ ButtonTypeEnum.ADD } onClick={ () => handleAddClick() }
                              disabled={ !phoneNumber }>
                    { t('receiver.button.add') }
                </CommonButton>
                <CommonButton btnType={ ButtonTypeEnum.NO_ICON }
                              onClick={ () => onOpenContactModal(true) }
                              variant='text'>
                    { t('receiver.button.addFromContacts') }
                </CommonButton>
            </Grid>
            <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } xl={ 12 }>
                { Boolean(validationError.errorMessage) &&
                  <Typography align='center' color='error' variant='body2'>
                      { validationError.errorMessage }
                  </Typography>
                }
            </Grid>
        </Grid>
    );
};

export default AddPhoneNumber;
