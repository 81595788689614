import { ChangeEvent, FC, SyntheticEvent, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, IconButton, Tab, Tabs, TextField, Typography } from '@mui/material';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import TabPanel from '../shared/TabPanel';
import apis from '../../auth/apis';
import { AxiosResponse } from 'axios';
import { PersonRelation, RelatedPerson } from 'company-info-service-api';
import { CheckCircle, Close, RemoveCircle, Search } from '@mui/icons-material';
import LoaderElement from '../loader/loader-element';
import { useTranslation } from 'react-i18next';

enum PersonDataType {
    COMPANY_PERSONAL_NUMBER,
    PERSONAL_NUMBER,
    FIRST_NAME,
    LAST_NAME,
}

interface IPersonInfo {
    companyPersonalNumber: string;
    personalNumber: string;
    firstName: string;
    lastName: string;
}

interface ICompanyInfoDialog {
    onClose: () => void;
    opened: boolean;
}

const CompanyInfoDialog: FC<ICompanyInfoDialog> = (props) => {
    const { onClose, opened } = props;

    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = useState<number>(0);

    const [companyPersonalNumber, setCompanyPersonalNumber] = useState<string>('');
    const [isPersonRelated, setIsPersonRelated] = useState<boolean | undefined>(undefined);

    const [personInfo, setPersonInfo] = useState<IPersonInfo>({
        companyPersonalNumber: '',
        firstName: '',
        lastName: '',
        personalNumber: '',
    });

    const [relatedPersons, setRelatedPersons] = useState<RelatedPerson[]>([]);
    const [isLoadingCompanyInfo, setIsLoadingCompanyInfo] = useState<boolean>(false);
    const [isPersonRelatedLoading, setIsPersonRelatedLoading] = useState<boolean>(false);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleRelatedPersonsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCompanyPersonalNumber(event.target.value);
    };

    const handleGetRelatedPersonsClick = () => {
        setIsLoadingCompanyInfo(true);
        setRelatedPersons([]);
        apis.companyInfoServiceApi()
            .getRelatedPersons(companyPersonalNumber)
            .then((result: AxiosResponse<RelatedPerson[]>) => {
                setRelatedPersons(result.data);
                setIsLoadingCompanyInfo(false);
            })
            .catch(() => {
                setIsLoadingCompanyInfo(false);
            });
    };

    const handlePersonInfoChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, label: PersonDataType) => {
        setPersonInfo({
            companyPersonalNumber: label == PersonDataType.COMPANY_PERSONAL_NUMBER ? event.target.value : personInfo.companyPersonalNumber,
            firstName: label == PersonDataType.FIRST_NAME ? event.target.value : personInfo.firstName,
            lastName: label == PersonDataType.LAST_NAME ? event.target.value : personInfo.lastName,
            personalNumber: label == PersonDataType.PERSONAL_NUMBER ? event.target.value : personInfo.personalNumber,
        });
    };

    const handleGetPersonInfo = () => {
        setIsPersonRelatedLoading(true);
        setIsPersonRelated(undefined);
        apis.companyInfoServiceApi()
            .getPersonRelation(personInfo.companyPersonalNumber, personInfo.personalNumber, personInfo.firstName, personInfo.lastName)
            .then((result: AxiosResponse<PersonRelation>) => {
                setIsPersonRelatedLoading(false);
                setIsPersonRelated(result.data.isPersonRelated);
            })
            .catch(() => {
                setIsPersonRelatedLoading(false);
            });
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleGetRelatedPersonsClick();
        }
    };

    return (
        <Dialog open={opened} fullWidth onClose={onClose}>
            <DialogContent>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs">
                    <Tab label={t('companyInfoDialog.tabs.label.relatedPersons')} />
                    <Tab label={t('companyInfoDialog.tabs.label.personRelation')} />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    <Box display={'flex'} mb={2}>
                        <TextField
                            size={'small'}
                            label={t('companyInfoDialog.relatedPersons.textField.label.companyOib')}
                            value={companyPersonalNumber}
                            onChange={handleRelatedPersonsChange}
                            fullWidth
                            onKeyDown={keyDownHandler}
                        />
                        <IconButton onClick={handleGetRelatedPersonsClick}>
                            <Search />
                        </IconButton>
                    </Box>

                    {relatedPersons.length === 0 && (
                        <Box textAlign={'center'}>
                            <Typography variant={'caption'}>{t('companyInfoDialog.relatedPersons.description.noData')}</Typography>
                        </Box>
                    )}

                    <Box>
                        {isLoadingCompanyInfo ? (
                            <LoaderElement size={22.5} />
                        ) : (
                            relatedPersons.map((rp: RelatedPerson, index: number) => (
                                <Box key={index} py={1} style={{ borderBottom: '1px solid lightgrey ' }}>
                                    <Typography>
                                        <strong>{rp.fullName}</strong>
                                    </Typography>
                                    <Typography variant={'subtitle1'}>{rp.companyName}</Typography>
                                    <Typography variant={'body2'}>{rp.role.finInfoRole}</Typography>
                                    <Typography variant={'body2'}>{rp.representationType?.finInfoRepresentation}</Typography>
                                    <Typography variant={'body2'}>{rp.subjectChangeDate}</Typography>
                                </Box>
                            ))
                        )}
                    </Box>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <Box>
                        <Typography variant={'caption'}>
                            {t('companyInfoDialog.relatedPersons.description.checkData')}
                        </Typography>
                    </Box>
                    <Box my={1}>
                        <TextField
                            label={t('companyInfoDialog.relatedPersons.textField.label.companyOib')}
                            value={personInfo.companyPersonalNumber}
                            onChange={(e) => handlePersonInfoChange(e, PersonDataType.COMPANY_PERSONAL_NUMBER)}
                            fullWidth
                            size={'small'}
                        />
                    </Box>
                    <Box my={1}>
                        <TextField
                            label={t('companyInfoDialog.relatedPersons.textField.label.personOib')}
                            value={personInfo.personalNumber}
                            onChange={(e) => handlePersonInfoChange(e, PersonDataType.PERSONAL_NUMBER)}
                            fullWidth
                            size={'small'}
                        />
                    </Box>
                    <Box my={1}>
                        <TextField
                            label={t('companyInfoDialog.relatedPersons.textField.label.personFirstName')}
                            value={personInfo.firstName}
                            onChange={(e) => handlePersonInfoChange(e, PersonDataType.FIRST_NAME)}
                            fullWidth
                            size={'small'}
                        />
                    </Box>
                    <Box my={1}>
                        <TextField
                            label={t('companyInfoDialog.relatedPersons.textField.label.personLastName')}
                            value={personInfo.lastName}
                            onChange={(e) => handlePersonInfoChange(e, PersonDataType.LAST_NAME)}
                            fullWidth
                            size={'small'}
                        />
                    </Box>
                    <Box textAlign={'center'}>
                        <CommonButton btnType={ButtonTypeEnum.SEARCH} onClick={handleGetPersonInfo}>
                            {t('companyInfoDialog.relatedPersons.button.label.search')}
                        </CommonButton>
                    </Box>
                    {isPersonRelatedLoading ? (
                        <LoaderElement />
                    ) : (
                        <Box textAlign={'center'} mt={4}>
                            {isPersonRelated != undefined ? (
                                isPersonRelated ? (
                                    <Box>
                                        <CheckCircle color={'success'} /> <Typography>{t('companyInfoDialog.relatedPersons.description.label.isRelated')}</Typography>
                                    </Box>
                                ) : (
                                    <Box>
                                        <RemoveCircle color={'error'} /> <Typography>{t('companyInfoDialog.relatedPersons.description.label.isNotRelated')}</Typography>
                                    </Box>
                                )
                            ) : (
                                <Box>
                                    <Typography variant={'caption'}>{t('companyInfoDialog.relatedPersons.description.label.enterData')}</Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                </TabPanel>
            </DialogContent>

            <DialogActions>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

export default CompanyInfoDialog;
