import type { FC } from 'react';
import { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, CircularProgress, Paper, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DocumentContent, DocumentInfo } from 'idy-certifier-api';
import {
    FileDownloadRounded,
    IosShareRounded,
    QrCode2Rounded,
    ShoppingBasketRounded,
    VerifiedUserRounded
} from '@mui/icons-material';
import { ISignatures } from '../../model/common/payments/ISignatures';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { PrivateRoutes } from '../../routes';

interface IDocumentActionsProps {
    onGenerateQrCode: () => void;
    onShareDocument: () => void;
    onSignDocument: () => void;
    isSigned: boolean;
    selectedDocumentDetails: DocumentInfo | undefined;
    signatures?: ISignatures;
    needSignatureFromCurrentUser: boolean;
    selectedDocumentContent: DocumentContent | undefined;
    isSigning: boolean;
}

const DocumentActions: FC<IDocumentActionsProps> = (props: IDocumentActionsProps) => {
    const {
        onGenerateQrCode,
        onShareDocument,
        onSignDocument,
        isSigned,
        selectedDocumentContent,
        selectedDocumentDetails,
        needSignatureFromCurrentUser,
        isSigning,
        signatures
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ value, setValue ] = useState<string>('');
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const { activeGroup } = useAppSelector(state => state.groups);

    const canUseOrganizationSignatures = !!activeGroup?.group.organizationUuid;
    const signaturesAvailable = ( signatures?.freeSignatures ?? 0 ) + ( signatures?.paidSignatures ?? 0 );
    const isSignaturesAvailable = signaturesAvailable > 0 || canUseOrganizationSignatures;

    const goToShop = () => {
        navigate(PrivateRoutes.private_shop_route.path);
    };

    return (
        <Paper sx={ { bottom: 0, left: 0, pl: lgUp ? '280px' : 'unset', position: 'fixed', right: 0, zIndex: 9999 } } elevation={ 3 }>
            <BottomNavigation showLabels
                              value={ value }
                              onChange={ (event, newValue) => {
                                  setValue(newValue);
                              } }>
                <BottomNavigationAction label={ t('dashboard.sidebar.documentDetails.button.downloadDocument') }
                                        icon={ <FileDownloadRounded /> }
                                        sx={ {
                                            color: '#5664d2'
                                        } }
                                        disabled={ !selectedDocumentContent?.value }
                                        href={ 'data:application/pdf;base64,' + selectedDocumentContent?.value }
                                        download={ selectedDocumentDetails?.title?.defaultText } />

                <BottomNavigationAction label={ t('dashboard.sidebar.documentDetails.button.generateQrCode') }
                                        icon={ <QrCode2Rounded /> }
                                        sx={ {
                                            color: '#5664d2'
                                        } }
                                        onClick={ onGenerateQrCode } />


                <BottomNavigationAction label={ t('dashboard.sidebar.documentDetails.button.share') }
                                        icon={ <IosShareRounded /> }
                                        sx={ {
                                            color: '#5664d2'
                                        } }
                                        onClick={ () => onShareDocument() } />

                {/* Ako nije potpisan i treba potpis, a ima potpisa za korištenje */ }
                { !isSigned && needSignatureFromCurrentUser && isSignaturesAvailable && (
                    <BottomNavigationAction label={
                        isSigning ?
                            t('dashboard.sidebar.documents.list.signAllDocuments.inProgress') :
                            t('dashboard.sidebar.documentDetails.button.notSigned')
                    }
                                            icon={ isSigning ? <CircularProgress color='error' size={ 15 } /> :
                                                <VerifiedUserRounded /> }
                                            sx={ {
                                                background: isSigning ? 'lightgrey' : '#5664d2',
                                                color: 'white',
                                                '&.Mui-selected': {
                                                    color: 'white'
                                                }
                                            } }
                                            disabled={ isSigning }
                                            onClick={ () => onSignDocument() } />
                ) }

                {/* Ako nije potpisan i treba potpis, a nema potpisa za korištenje */ }
                { !isSigned && needSignatureFromCurrentUser && !isSignaturesAvailable && (
                    <BottomNavigationAction label={ t('shop.signatures.buy.documentDetails') }
                                            icon={ <ShoppingBasketRounded /> }
                                            onClick={ () => goToShop() } />
                ) }

                {/* Ako je potpisan */ }
                { isSigned && (
                    <BottomNavigationAction label={ t('dashboard.sidebar.documentDetails.button.signed') }
                                            icon={ <VerifiedUserRounded /> }
                                            sx={ {
                                                background: 'lightgrey',
                                                color: 'white',
                                                '&.Mui-selected': {
                                                    color: 'white'
                                                }
                                            } }
                                            disabled />
                ) }

            </BottomNavigation>
        </Paper>
    );
};

export default DocumentActions;
