import type { FC } from 'react';
import { useEffect } from 'react';
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { ApiDocument } from 'document-scanner-api';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import SkeletonCard from '../skeletons/SkeletonCard';
import { getProfileData } from '../../slices/profile-data';
import VerifyProfile from './VerifyProfile';

const ProfileGeneral: FC = () => {
    const { t } = useTranslation();
    const {profileData, isLoading } = useAppSelector((state) => state.profileData);
    const dispatch = useAppDispatch();

    const isProfileVerified = Boolean(profileData?.documents && profileData?.documents.length >= 1);

    useEffect(() => {
        !profileData && dispatch(getProfileData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading && <SkeletonCard />}

            {!isLoading && !isProfileVerified && <VerifyProfile />}

            {profileData?.documents?.map((document: ApiDocument) => (
                <Card key={document.uuid}>
                    <CardContent>
                        <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
                            <Grid item lg={6} md={6} xl={6} xs={12} sm={12}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('groups.settings.user.fullName')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.fullName?.visual}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.document')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.info?.countryCode + ' ' + document.info?.documentType}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.documentNumber')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.number?.mrz}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.issueDate')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.dateOfIssue?.visual}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.expiryDate')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.dateOfExpiry?.visual}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.issuer')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.issuedBy?.visual}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.sex')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.sex?.visual}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.birthDate')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.dateOfBirth?.visual}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.birthPlace')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.placeOfBirth?.visual}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.personalNumber')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.personalNumber?.mrz}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.address')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.address?.visual}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography color='textPrimary' variant='subtitle2'>
                                                    {t('dashboard.profile.general.nationalityCode')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography color='textSecondary' variant='body2'>
                                                    {document.data?.nationalityCode?.visual}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item lg={6} md={6} xl={6} xs={12} sm={12}>
                                {document.images?.frontImage?.image && (
                                    <img
                                        src={`data:image/jpeg;base64,${document.images?.frontImage?.image}`}
                                        style={{ width: '80%', margin: '20px 0', borderRadius: '10px' }}
                                        alt='img'
                                    />
                                )}
                                {document.images?.backImage?.image && (
                                    <img
                                        src={`data:image/jpeg;base64,${document.images?.backImage?.image}`}
                                        style={{ width: '80%', margin: '20px 0', borderRadius: '10px' }}
                                        alt='img'
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
        </>
    );
};

export default ProfileGeneral;
