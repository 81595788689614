import { RouteObject } from 'react-router';
import AuthGuard from './components/auth-guard/AuthGuard';
import DocumentUploadPage from './pages/documents/DocumentUploadPage';
import DocumentDetailsPage from './pages/documents/details/DocumentDetailsPage';
import Login from './pages/authentication/Login';
import UserContactsSummaryPage from './pages/user-contacts/UserContactsSummaryPage';
import UserContactDetails from './pages/user-contacts/UserContactDetails';
import NewUserContactsPage from './pages/user-contacts/NewUserContactsPage';
import ProfilePage from 'pages/profile/ProfilePage';
import CheckoutPage from './pages/payments/CheckoutPage';
import TermsSaleStatement from './components/statements/TermsSaleStatement';
import DataProtectionStatement from './components/statements/DataProtectionStatement';
import DataUsageStatement from './components/statements/DataUsageStatement';
import ConversionStatement from './components/statements/ConversionStatement';
import GroupPendingPage from './pages/groups/GroupPendingPage';
import PrivateDashboardLayout from './components/layouts/private/PrivateDashboardLayout';
import GroupsPage from './pages/groups/GroupsPage';
import MembersPage from './pages/groups/MembersPage';
import GroupSettingsPage from './pages/groups/GroupSettingsPage';
import GroupDocumentsPage from './pages/groups/GroupDocumentsPage';
import GroupDashboardLayout from './components/layouts/group/GroupDashboardLayout';
import AddMemberPage from './pages/groups/AddMemberPage';
import WsPayResult from './pages/payments/WsPayResult';
import DocumentSignSimplePage from './pages/documents/DocumentSignSimplePage';
import NewOrganization from './pages/organizations/NewOrganization';
import OrganizationListPage from './pages/organizations/OrganizationListPage';
import OrganizationDetailsPage from './pages/organizations/OrganizationDetailsPage';
import OrganizationInvitesPage from './pages/organizations/OrganizationInvitesPage';
import MySurveysPage from './pages/survey/MySurveysPage';
import MySurveyPage from './pages/survey/MySurveyPage';
import EditSurveyPage from './pages/survey/EditSurveyPage';
import SurveyAdminPage from './pages/survey/SurveyAdminPage';
import SurveyUsersPage from './pages/survey/SurveyUsersPage';
import SurveyUserPage from './pages/survey/SurveyUserPage';
import Shop from './components/profile/plans/Shop';
import SurveyPdfPage from './pages/survey/SurveyPdfPage';
import MySurveyUnprotected from './pages/survey/MySurveyUnprotected';
import { DocumentsRetrieveType } from 'model/common/documents/DocumentsRetrieveType';
import DocumentsPage from './pages/documents/DocumentsPage';
import { RedirectPage } from './pages/oauth/RedirectPage';
import SurveyWalletDataPage from './pages/survey/SurveyWalletDataPage';
import { AuthorizationCodeCallbackPage } from './pages/oauth/AuthorizationCodeCallbackPage';
import { AuthorizePage } from './pages/oauth/AuthorizePage';
import Page404 from './components/layouts/common/Page404';
import UnprotectedRoutesLayout from './components/layouts/common/UnprotectedRoutesLayout';

export class GroupRoutes {
    public static groups_members_route = {
        path: '/dashboard/groups/:groupUuid/members',
        element: <MembersPage />
    };

    public static groups_settings_route = {
        path: '/dashboard/groups/:groupUuid/settings',
        element: <GroupSettingsPage />
    };

    public static groups_sent_documents_route = {
        path: '/dashboard/groups/:groupUuid/sent-documents',
        element: <GroupDocumentsPage type={ DocumentsRetrieveType.SENT } />
    };

    public static groups_received_documents_route = {
        path: '/dashboard/groups/:groupUuid/received-documents',
        element: <GroupDocumentsPage type={ DocumentsRetrieveType.RECEIVED } />
    };

    public static groups_new_document_route = {
        path: '/dashboard/groups/:groupUuid/newDocument',
        element: <DocumentUploadPage />
    };

    public static groups_add_member_route = {
        path: '/dashboard/groups/:groupUuid/members/add',
        element: <AddMemberPage />
    };

    public static group_document_details_route = {
        path: '/dashboard/groups/:groupUuid/documents/:documentUuid',
        element: <DocumentDetailsPage />
    };

    public static group_survey_admin_route = {
        path: '/dashboard/groups/:groupUuid/surveys/survey-admin',
        element: <SurveyAdminPage />
    };

    public static group_survey_users_results_route = {
        path: '/dashboard/groups/:groupUuid/surveys/:surveyUuid/submitted',
        element: <SurveyUsersPage />
    };

    public static group_survey_user_results_route = {
        path: '/dashboard/groups/:groupUuid/survey-admin/:surveyUserUuid/result',
        element: <SurveyUserPage />
    };

    public static group_my_survey_route = {
        path: '/dashboard/groups/:groupUuid/my-surveys/:surveyUuid',
        element: <MySurveyPage />
    };

    public static group_edit_survey_route = {
        path: '/dashboard/groups/:groupUuid/survey-admin/:surveyUuid',
        element: <EditSurveyPage />
    };
}

export class PrivateRoutes {
    public static private_dashboard_route = {
        path: '/dashboard',
        element: <DocumentsPage type={ DocumentsRetrieveType.RECEIVED } />
    };

    public static private_received_documents_route = {
        path: '/dashboard/documents/received',
        element: <DocumentsPage type={ DocumentsRetrieveType.RECEIVED } />
    };

    public static private_sent_documents_route = {
        path: '/dashboard/documents/sent',
        element: <DocumentsPage type={ DocumentsRetrieveType.SENT } />
    };

    public static private_document_details_route_sentReceived = {
        path: '/dashboard/documents/:sentOrReceived/:documentUuid',
        element: <DocumentDetailsPage />
    };

    public static private_document_details_route = {
        path: '/dashboard/documents/:documentUuid',
        element: <DocumentDetailsPage />
    };

    public static private_new_document_route = {
        path: '/dashboard/documents/upload',
        element: <DocumentUploadPage />
    };

    public static private_contacts_route = {
        path: '/dashboard/userContacts/allContacts',
        element: <UserContactsSummaryPage />
    };

    public static private_contact_details_route = {
        path: '/dashboard/userContacts/:contactUuid',
        element: <UserContactDetails />
    };

    public static private_new_contact_route = {
        path: '/dashboard/userContacts/newContact',
        element: <NewUserContactsPage />
    };

    public static private_settings_route = {
        path: '/dashboard/profile/:activeTab',
        element: <ProfilePage />
    };

    public static private_shop_route = {
        path: '/dashboard/shop',
        element: <Shop />
    };

    public static private_groups_route = {
        path: '/dashboard/groups',
        element: <GroupsPage />
    };

    public static private_groups_new_route = {
        path: '/dashboard/groups/new',
        element: <GroupsPage />
    };

    public static private_groups_pending_membership_route = {
        path: '/dashboard/groupsPending/:membershipUuid',
        element: <GroupPendingPage />
    };

    public static private_groups_pending_route = {
        path: '/dashboard/groupsPending',
        element: <GroupPendingPage />
    };

    public static private_checkout_route = {
        path: '/dashboard/checkout',
        element: <CheckoutPage />
    };

    public static private_organizations_new_route = {
        path: '/dashboard/organizations/new',
        element: <NewOrganization />
    };

    public static private_organizations_list_route = {
        path: '/dashboard/organizations/list',
        element: <OrganizationListPage />
    };

    public static private_organizations_details_route = {
        path: '/dashboard/organizations/:organizationUuid',
        element: <OrganizationDetailsPage />
    };

    public static private_organizations_invitations_route = {
        path: '/dashboard/organizations/invites',
        element: <OrganizationInvitesPage />
    };

    public static private_organizations_invitations_single_route = {
        path: '/dashboard/organizations/invites/:inviteUuid',
        element: <OrganizationInvitesPage />
    };

    public static private_my_surveys_route = {
        path: '/dashboard/my-surveys',
        element: <MySurveysPage />
    };

    public static private_survey_admin_route = {
        path: '/dashboard/survey-admin',
        element: <SurveyAdminPage />
    };

    public static private_edit_survey_route = {
        path: '/dashboard/survey-admin/:surveyUuid',
        element: <EditSurveyPage />
    };

    public static private_survey_users_results_route = {
        path: '/dashboard/survey-admin/:surveyUuid/submitted',
        element: <SurveyUsersPage />
    };

    public static private_survey_user_results_route = {
        path: '/dashboard/survey-admin/:surveyUserUuid/result',
        element: <SurveyUserPage />
    };

    public static private_survey_user_wallet_data_route = {
        path: '/dashboard/survey-admin/:surveyUserUuid/wallet-data',
        element: <SurveyWalletDataPage />
    };

    public static private_survey_user_results_pdf_route = {
        path: '/dashboard/survey-admin/:surveyUserUuid/pdf',
        element: <SurveyPdfPage />
    };

    public static my_survey_route = {
        path: '/dashboard/my-surveys/:surveyUuid',
        element: <MySurveyPage />
    };
}

export class UnprotectedRoutes {
    public static unprotected_login_route = {
        path: '/login',
        element: <Login />
    };

    public static unprotected_refresh_route = {
        path: '/refresh',
        element: <AuthorizePage />
    };

    public static unprotected_oauth_redirect_route = {
        path: '/oauthRedirect',
        element: <AuthorizationCodeCallbackPage />
    };

    public static unprotected_simple_redirect_route = {
        path: '/redirect',
        element: <RedirectPage />
    };

    public static unprotected_wsPay_result_route = {
        path: '/wsPayResult',
        element: <WsPayResult />
    };

    public static document_sign_simple_route = {
        path: 'document-sign/:shareCode',
        element: <DocumentSignSimplePage />
    };

    public static terms_route = {
        path: '/terms',
        element: <TermsSaleStatement />
    };

    public static terms_sale_route = {
        path: '/terms/sale',
        element: <TermsSaleStatement />
    };

    public static terms_protection_route = {
        path: '/terms/protection',
        element: <DataProtectionStatement />
    };

    public static terms_data_usage_route = {
        path: '/terms/dataUsage',
        element: <DataUsageStatement />
    };

    public static terms_conversion_route = {
        path: '/terms/conversion',
        element: <ConversionStatement />
    };

    public static my_survey_unprotected_route = {
        path: 'my-surveys/public/:surveyUuid',
        element: (
            <UnprotectedRoutesLayout>
                <MySurveyUnprotected />
            </UnprotectedRoutesLayout>
        )
    };
}

const PRIVATE_DASHBOARD_ROUTE: RouteObject = {
    path: 'dashboard',
    element: (
        <AuthGuard>
            <PrivateDashboardLayout />
        </AuthGuard>
    ),
    children: [
        PrivateRoutes.private_dashboard_route,
        PrivateRoutes.private_received_documents_route,
        PrivateRoutes.private_sent_documents_route,
        PrivateRoutes.private_document_details_route_sentReceived,
        PrivateRoutes.private_document_details_route,
        PrivateRoutes.private_new_document_route,
        PrivateRoutes.private_contacts_route,
        PrivateRoutes.private_contact_details_route,
        PrivateRoutes.private_new_contact_route,
        PrivateRoutes.private_settings_route,
        PrivateRoutes.private_shop_route,
        PrivateRoutes.private_groups_route,
        PrivateRoutes.private_groups_new_route,
        PrivateRoutes.private_groups_pending_membership_route,
        PrivateRoutes.private_groups_pending_route,
        PrivateRoutes.private_checkout_route,
        PrivateRoutes.private_organizations_new_route,
        PrivateRoutes.private_organizations_list_route,
        PrivateRoutes.private_organizations_details_route,
        PrivateRoutes.private_organizations_invitations_route,
        PrivateRoutes.private_organizations_invitations_single_route,
        PrivateRoutes.private_my_surveys_route,
        PrivateRoutes.private_survey_admin_route,
        PrivateRoutes.private_edit_survey_route,
        PrivateRoutes.private_survey_users_results_route,
        PrivateRoutes.private_survey_user_results_route,
        PrivateRoutes.private_survey_user_wallet_data_route,
        PrivateRoutes.private_survey_user_results_pdf_route,
        PrivateRoutes.my_survey_route
    ]
};

const GROUPS_DASHBOARD_ROUTE: RouteObject = {
    path: 'dashboard/groups',
    element: (
        <AuthGuard>
            <GroupDashboardLayout />
        </AuthGuard>
    ),
    children: [
        GroupRoutes.groups_members_route,
        GroupRoutes.groups_settings_route,
        GroupRoutes.groups_sent_documents_route,
        GroupRoutes.groups_received_documents_route,
        GroupRoutes.groups_new_document_route,
        GroupRoutes.groups_add_member_route,
        GroupRoutes.group_document_details_route,
        GroupRoutes.group_survey_admin_route,
        GroupRoutes.group_survey_users_results_route,
        GroupRoutes.group_my_survey_route,
        GroupRoutes.group_survey_user_results_route,
        GroupRoutes.group_edit_survey_route
    ]
};

export const HOME_ROUTE: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <PrivateDashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <DocumentsPage type={ DocumentsRetrieveType.RECEIVED } />
        }
    ]
};

const PAGE_404: RouteObject = {
    path: '*',
    element: (
        <Page404 />
    )
};

const routes: RouteObject[] = [
    UnprotectedRoutes.unprotected_login_route,
    UnprotectedRoutes.unprotected_refresh_route,
    UnprotectedRoutes.unprotected_oauth_redirect_route,
    UnprotectedRoutes.unprotected_simple_redirect_route,
    UnprotectedRoutes.unprotected_wsPay_result_route,
    UnprotectedRoutes.document_sign_simple_route,
    UnprotectedRoutes.terms_route,
    UnprotectedRoutes.terms_sale_route,
    UnprotectedRoutes.terms_protection_route,
    UnprotectedRoutes.terms_conversion_route,
    UnprotectedRoutes.terms_data_usage_route,
    UnprotectedRoutes.my_survey_unprotected_route,
    HOME_ROUTE,
    PRIVATE_DASHBOARD_ROUTE,
    GROUPS_DASHBOARD_ROUTE,
    PAGE_404
];

export default routes;
