import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import hrFlag from '../../assets/icons/hr_flag.svg';
import enFlag from '../../assets/icons/uk_flag.svg';
import { useTranslation } from 'react-i18next';
import auth from '../../auth/auth';
import axios from 'axios';
import { getAccessToken, isUserLoggedIn } from '../../auth/idyAxios';

const LanguagePopover: FC = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const { i18n } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    const languageOptions = {
        en: {
            icon: enFlag,
            label: 'English',
        },
        hr: {
            icon: hrFlag,
            label: 'Croatian',
        },
    };

    useEffect(() => {
        const lang = auth.getIdyLanguage();
        if (lang != 'undefined' && lang !== null) {
            i18n.changeLanguage(lang);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleChangeLanguage = async (language: string): Promise<void> => {
        i18n.changeLanguage(language);
        auth.setIdyLanguage(language);
        const isLoggedIn = isUserLoggedIn();
        setOpen(false);
        const client = auth.getClientSessionUuid();
        if (client && isLoggedIn) {
            axios
                .post(
                    `${process.env.REACT_APP_LOGIN_MANAGER_API_URL}/api/v1/languages`,
                    { languageCode: language },
                    {
                        headers: {
                            Authorization: `Bearer ${ getAccessToken()?.access_token }`,
                            'X-CLIENT-SESSION-UUID': auth.getClientSessionUuid(),
                            'X-IDY-LANG': auth.getIdyLanguage(),
                            'X-ORGANIZATION-UUID': auth.getOrganizationUuid(),
                            'X-USER-PIN': auth.getUserPin(),
                        },
                    }
                )
                .then()
                .catch();
            // apis.langaugesControllerApi().submitLanguage(client, { languageCode: language as LanguageCodeRequestLanguageCodeEnum });
        }
    };

    const selectedOption = languageOptions[i18n.language];

    return (
        <>
            <IconButton onClick={handleOpen} ref={anchorRef}>
                <Box
                    sx={{
                        '& img': {
                            width: '100%',
                        },
                        display: 'flex',
                        height: 20,
                        width: 20,
                    }}>
                    <img alt={selectedOption?.label} src={selectedOption?.icon} />
                </Box>
            </IconButton>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 240 },
                }}>
                {Object.keys(languageOptions).map((language) => (
                    <MenuItem onClick={() => handleChangeLanguage(language)} key={language}>
                        <ListItemIcon>
                            <Box
                                sx={{
                                    '& img': {
                                        width: '100%',
                                    },
                                    display: 'flex',
                                    height: 20,
                                    width: 20,
                                }}>
                                <img alt={languageOptions[language]?.label ?? languageOptions.en.label} src={languageOptions[language]?.icon ?? languageOptions.en.icon} />
                            </Box>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography color="textPrimary" variant="subtitle2">
                                    {languageOptions[language]?.label}
                                </Typography>
                            }
                        />
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
};

export default LanguagePopover;
