import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Box, Dialog, DialogActions, DialogContent, IconButton, TextField, Typography} from '@mui/material';
import { Close } from '@mui/icons-material';
import CommonButton, { ButtonTypeEnum } from '../shared/CommonButton';
import {SurveyAdmin} from 'survey-service-api';

interface INewFromExistingDialogProps {
    open: boolean;
    onClose: (createFromExistingParams: ISurveyFromExisting) => void;
    selectedSurvey: SurveyAdmin;
}

interface ISurveyFromExisting {
    confirmed: boolean;
    surveyUuid: string;
    groupUuid: string | undefined;
}

const NewFromExistingDialog: FC<INewFromExistingDialogProps> = (props) => {
    const { t } = useTranslation();

    const { open, onClose, selectedSurvey } = props;

    const closeParams: ISurveyFromExisting = {
        confirmed: false,
        surveyUuid: selectedSurvey.uuid,
        groupUuid: undefined
    };

    const [newFromExistingParams, setNewFromExistingParams] = useState<ISurveyFromExisting>(
        {
            confirmed: true,
            surveyUuid: selectedSurvey.uuid,
            groupUuid: undefined
        });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setNewFromExistingParams((prevParams) => ({
            ...prevParams,
            groupUuid: value,
        }));
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Box display="flex" justifyContent="right" m={1} borderBottom="1px solid lightgrey">
                <IconButton onClick={() => onClose(closeParams)}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent sx={{ p: 4 }}>
                <Box>
                <Typography variant={'body1'}>New survey will be created from existing:</Typography>
                <Typography variant={'body2'}>Survey UUID: {selectedSurvey.uuid}</Typography>
                <Typography variant={'body2'}>Survey name: {selectedSurvey.name}</Typography>
                <Typography variant={'body2'}>Survey description: {selectedSurvey.description}</Typography>
                </Box>

                <Typography variant={'body1'}>
                    If you want to assign survey to certain group enter that groupUuid. You can leave it empty and in that case survey will be yours.
                </Typography>

                <TextField
                    fullWidth={true}
                    size="small"
                    sx={{ mr: 2, mt: 2 }}
                    id="outlined-controlled"
                    value={newFromExistingParams.groupUuid}
                    label={'Group uuid'}
                    placeholder={'Group uuid'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(event);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <CommonButton btnType={ButtonTypeEnum.SEND} onClick={() => onClose(newFromExistingParams)} sx={{ mt: 1, mr: 1 }}>
                    {t('survey.SurveyAdminPage.actionButton.newFromExisting')}
                </CommonButton>
            </DialogActions>
        </Dialog>
    );
};

export default NewFromExistingDialog;
