import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { AnswerOption, Option, OptionAnswerRequest } from 'survey-service-api';
import { Box, TextField } from '@mui/material';

interface IInputQuestionProps {
    answerOptions: (options: OptionAnswerRequest[]) => void;
    options: Option[];
    prefilledOptions?: AnswerOption[];
}

const InputQuestion: FC<IInputQuestionProps> = (props) => {
    const { answerOptions, options, prefilledOptions } = props;

    const mappedOptions: OptionAnswerRequest[] = prefilledOptions
        ? prefilledOptions.map((po: AnswerOption) => {
              return { optionUuid: po.option?.uuid, inputValue: po.inputText } as OptionAnswerRequest;
          })
        : options.map((o: Option) => {
              return { optionUuid: o.uuid, inputValue: '' } as OptionAnswerRequest;
          });

    const [inputFields, setInputFields] = useState<OptionAnswerRequest[]>(mappedOptions);

    useEffect(() => {
        answerOptions(inputFields);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, optionUuid: string) => {
        const { value } = event.target;
        setInputFields((prevFilledInputFields) =>
            prevFilledInputFields.map((item) => (item.optionUuid === optionUuid ? { ...item, inputValue: value } : item))
        );
    };

    const getLabel = (uuid: string): string => {
        return options.find((value) => value.uuid === uuid)?.label ?? '';
    };

    return (
        <Box>
            {inputFields &&
                inputFields.map((o: OptionAnswerRequest) => (
                    <Box key={o.optionUuid}>
                        <TextField
                        size="small"
                        sx={{ mr: 2, mt: 2 }}
                        id="outlined-controlled"
                        value={o.inputValue}
                        label={getLabel(o.optionUuid)}
                        placeholder={getLabel(o.optionUuid)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event, o.optionUuid);
                        }}
                    />
                    </Box>

                ))}
        </Box>
    );
};

export default InputQuestion;
