import type { FC } from 'react';
import {
    ContactType,
    IDocumentReceiver,
    IPhone
} from '../../model/common/documents/document-user';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { ContactResponse, EmailResponse } from 'contacts-service-api';
import AddEmailOrPhoneNumber from './AddEmailOrPhoneNumber';
import { PhoneResponse } from 'contacts-service-api';
import { IContact } from '../ContactsChooserModal';

interface IAddReceiverProps {
    sharedWith: IDocumentReceiver[];
    userContacts?: ContactResponse[];
    onAddReceiver: (receiver: IDocumentReceiver[]) => void;
}

const AddReceiver: FC<IAddReceiverProps> = (props: IAddReceiverProps) => {
    const { onAddReceiver, userContacts, sharedWith } = props;
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const existingEmails = sharedWith
        ?.reduce((acc: string[], r) => {
            if (r.contactType === ContactType.EMAIL) {
                acc.push(r.email as string);
            }
            return acc;
        }, [] as string[]);

    const existingPhones = sharedWith
        ?.reduce((acc: IPhone[], r) => {
            if (r.contactType === ContactType.MOBILE_NUMBER) {
                acc.push(r.phone as IPhone);
            }
            return acc;
        }, [] as IPhone[]);

    const onAddPhone = (phones: IPhone[]) => {
        const filteredPhones = phones
            ?.filter(p => !existingPhones.some(p2 => p2?.dialCode === p.dialCode && p2?.number === p.number))
            ?.map(phone => {
                const contactName = userContacts
                    ?.find((contact: ContactResponse) =>
                        contact.phones?.some((p: PhoneResponse) =>
                            p.value.dialCode === phone.dialCode && p.value.number === phone.number
                        )
                    )?.name ?? '';

                return {
                    contactType: ContactType.MOBILE_NUMBER,
                    name: contactName,
                    phone: phone,
                    signatureRequested: true
                } as IDocumentReceiver;
            });
        onAddReceiver(filteredPhones);
    };

    const onAddContacts = (contacts: IContact[]) => {
        const newReceivers = [] as IDocumentReceiver[];
        contacts.forEach((c) => {
            newReceivers.push({
                contactType: c.type === 'email' ? ContactType.EMAIL : ContactType.MOBILE_NUMBER,
                email: c.email,
                name: c.name,
                phone: c.phone ? {
                    dialCode: c.phone.dialCode,
                    number: c.phone.number
                } as IPhone : undefined,
                signatureRequested: true
            } as IDocumentReceiver);
        });
        onAddReceiver(newReceivers);
    };

    const onAddEmail = (emails: string[]) => {
        const filteredEmails = emails
            ?.filter(email => !existingEmails.some(e2 => e2 === email))
            ?.map(email => {
                const contactName = userContacts
                    ?.find((contact: ContactResponse) =>
                        contact.emails?.some((e2: EmailResponse) =>
                            e2.email === email
                        )
                    )?.name ?? '';

                return {
                    contactType: ContactType.EMAIL,
                    email: email,
                    name: contactName,
                    signatureRequested: true
                } as IDocumentReceiver;
            });
        onAddReceiver(filteredEmails);
    };

    return (
        <Box sx={ {
            mt: 3,
            p: smUp ? 3 : 0,
            pr: 1,
            pt: 1,
            width: '100%'
        } }>
            <AddEmailOrPhoneNumber existingEmails={ existingEmails }
                                   existingPhones={ existingPhones }
                                   onAddContacts={ onAddContacts }
                                   onAddEmails={ onAddEmail }
                                   onAddPhones={ onAddPhone } />
        </Box>
    );
};

export default AddReceiver;
