import type { ReactNode } from 'react';
import { experimentalStyled } from '@mui/material';

export interface DashboardLayoutProps {
    children?: ReactNode;
}

export const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
}));

export const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingBottom: '80px',
    paddingTop: '64px',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px',
    },
}));

export const UnprotectedRoutesLayoutWrapper = experimentalStyled('div')(() => ({
    paddingBottom: '100px'
}));

export const DashboardLayoutContainer = experimentalStyled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
});

export const DashboardLayoutContent = experimentalStyled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    WebkitOverflowScrolling: 'touch'
});
