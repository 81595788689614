import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    deleteSurvey,
    deleteSurveyUser,
    generatePreviewPdf,
    getSharedSurveyUsers,
    getSurvey,
    shareSurvey
} from '../../slices/survey';
import Page from '../../components/shared/Page';
import { Box, Card, CardActions, CardContent, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import {GroupRoutes, PrivateRoutes} from '../../routes';
import { CheckCircle, Close, QuestionAnswer } from '@mui/icons-material';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import SkeletonBlock from '../../components/skeletons/SkeletonBlock';
import { Phone, SharedWith, SurveyInvitation, SurveyUserAdmin } from 'survey-service-api';
import EmptyState from '../../components/empty-states/EmptyState';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getUserContacts } from '../../slices/user-contacts';
import SurveyPreviewDialog from '../../components/survey/SurveyPreviewDialog';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';
import AddEmailOrPhoneNumber from '../../components/receivers/AddEmailOrPhoneNumber';
import { IPhone } from '../../model/common/documents/document-user';
import { IContact } from '../../components/ContactsChooserModal';
import SurveyReceiverList from '../../components/survey/SurveyReceiverList';
import { RolesHelper } from '../../utils/roles-helper';

const SurveyUsersPage: FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { surveyUuid, groupUuid } = params;

    const { isLoadingSurveyUsers, survey, pdfBase64, isLoadingPdf, sharedSurveyUsers } = useAppSelector((state) => state.survey);
    const { userContacts } = useAppSelector((state) => state.userContacts);

    const [previewDialogOpened, setPreviewDialogOpened] = useState<boolean>(false);
    const [deleteSurveyDialogOpened, setDeleteSurveyDialogOpened] = useState<boolean>(false);
    const [newUsers, setNewUsers] = useState<SurveyInvitation[]>([]);
    const [filteredSurveyUsers, setFilteredSurveyUsers] = useState<SurveyUserAdmin[]>(sharedSurveyUsers);
    const [searchValue, setSearchValue] = useState<string>('');

    const newUsersEmails = newUsers.filter((user: SurveyInvitation) => user.email).map((user: SurveyInvitation) => user.email as string);

    const newUsersPhones = newUsers
        .filter((user: SurveyInvitation) => user.phone)
        .map((user: SurveyInvitation) => {
            const phone = user.phone as Phone;
            return {
                dialCode: phone.dialCode,
                number: phone.number,
            } as IPhone;
        });

    useEffect(() => {
        if (surveyUuid) {
            dispatch(getSurvey(surveyUuid));
            dispatch(getSharedSurveyUsers(surveyUuid));
        }

        if (!userContacts || userContacts.length === 0) {
            dispatch(getUserContacts());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilteredSurveyUsers(sharedSurveyUsers);
    }, [sharedSurveyUsers]);

    useEffect(() => {
        if (searchValue.length === 0) {
            setFilteredSurveyUsers(sharedSurveyUsers);
        } else {
            const filtered = sharedSurveyUsers.filter((value) => value.sharedWith?.email?.includes(searchValue));
            setFilteredSurveyUsers(filtered);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const handleSurveyUserClick = (uuid: string) => {

        if (groupUuid != null) {
            navigate(GroupRoutes.group_survey_user_results_route.path.replace(':groupUuid', groupUuid ?? '').replace(':surveyUserUuid', uuid ?? ''));
        } else {
            navigate(PrivateRoutes.private_survey_user_results_route.path.replace(':surveyUserUuid', uuid ?? ''));
        }
    };

    const handleSurveyUserDelete = (uuid?: string) => {
        if (uuid) {
            dispatch(deleteSurveyUser(uuid));
        }
    };

    const onAddContacts = (contacts: IContact[]) => {
        const newUsers = contacts?.map((contact) => {
            return {
                email: contact.email,
                phone: contact.phone,
            } as SurveyInvitation;
        });
        setNewUsers((prevState) => [...prevState, ...newUsers]);
    };

    const onAddEmails = (emails: string[]) => {
        const newUsers = emails?.map((email) => {
            return {
                email: email,
            } as SurveyInvitation;
        });
        setNewUsers((prevState) => [...prevState, ...newUsers]);
    };

    const onAddPhones = (phones: IPhone[]) => {
        const newUsers = phones?.map((phone) => {
            return {
                phone: {
                    dialCode: phone.dialCode,
                    number: phone.number,
                },
            } as SurveyInvitation;
        });
        setNewUsers((prevState) => [...prevState, ...newUsers]);
    };

    const userLabel = (sharedWith: SharedWith): string | undefined => {
        if (sharedWith) {
            if (sharedWith.fullName) {
                return `${sharedWith.fullName}`;
            }

            if (sharedWith.email) {
                return `${sharedWith.email}`;
            }

            if (sharedWith.dialCode && sharedWith.phoneNumber) {
                return `+${sharedWith.dialCode.concat(sharedWith.phoneNumber)}`;
            }
        }

        return undefined;
    };

    const navigateToForm = (uuid: string | undefined) => {
        if (groupUuid && uuid) {
            navigate(GroupRoutes.group_edit_survey_route.path.replace(':groupUuid', groupUuid ?? '').replace(':surveyUuid', uuid ?? ''));
        } else if (uuid) {
            navigate(PrivateRoutes.private_edit_survey_route.path.replace(':surveyUuid', uuid ?? ''));
        }
    };

    const handleImportMailsFromExcelFile = (emails: string[]) => {
        const newUsers = emails.map((email) => {
            return {
                email: email,
            } as SurveyInvitation;
        });
        setNewUsers((prevState) => [...prevState, ...newUsers]);
    };

    const handlePreviewSurvey = () => {
        if (survey) {
            dispatch(generatePreviewPdf(survey.uuid));
            setPreviewDialogOpened(true);
        }
    };

    const handleClosePreviewDialog = () => {
        setPreviewDialogOpened(false);
    };

    const handleDeleteSurveyClick = () => {
        setDeleteSurveyDialogOpened(true);
    };

    const handleDeleteSurvey = () => {
        if (surveyUuid) {
            dispatch(deleteSurvey(surveyUuid));
            navigate(PrivateRoutes.private_survey_admin_route.path);
        }
        setDeleteSurveyDialogOpened(false);
    };

    const handleCloseDeleteSurvey = () => {
        setDeleteSurveyDialogOpened(false);
    };

    const handleRemoveReceiver = (index: number) => {
        const updatedArray = [...newUsers];
        updatedArray.splice(index, 1);
        setNewUsers(updatedArray);
    };

    const handleShare = () => {
        if (surveyUuid && newUsers.length > 0) {
            dispatch(shareSurvey(surveyUuid, { sharedWith: newUsers }));
            setNewUsers([]);
        }
    };

    const handleClearReceivers = () => {
        setNewUsers([]);
    };

    return (
        <Page
            pageTitle={t('survey.SurveyUsersPage.title')}
            actionButton={
                <Box>
                    {RolesHelper.hasSurveyCreatorRole() && survey && survey.sharedWith.length <= 1 && (
                        <CommonButton btnType={ButtonTypeEnum.EDIT} onClick={() => navigateToForm(survey.uuid)} sx={{ mr: 1 }}>
                            {t('survey.SurveyAdminPage.cardActions.button.edit')}
                        </CommonButton>
                    )}
                    <CommonButton btnType={ButtonTypeEnum.DETAILS} onClick={handlePreviewSurvey} sx={{ mr: 1 }} disabled={!survey}>
                        {t('survey.SurveyUsersPage.button.surveyPreview.preview')}
                    </CommonButton>
                    <CommonButton btnType={ButtonTypeEnum.DELETE} onClick={handleDeleteSurveyClick}>
                        {t('survey.SurveyUsersPage.actionButton.delete')}
                    </CommonButton>
                </Box>
            }>
            {survey && (
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h5">{survey.name}</Typography>
                    <Typography variant="body1">{survey.description}</Typography>
                </Box>
            )}

            <Box sx={{ mb: 3 }}>
                <Typography variant="h6">{t('survey.SurveyUsersPage.surveyUsers.shareHeader.title')}</Typography>
                <Typography variant="caption">{t('survey.SurveyUsersPage.surveyUsers.shareHeader.description')}</Typography>
            </Box>

            <Card>
                <AddEmailOrPhoneNumber
                    existingEmails={[...newUsersEmails]}
                    existingPhones={[...newUsersPhones]}
                    onAddContacts={onAddContacts}
                    onAddEmails={onAddEmails}
                    onAddPhones={onAddPhones}
                    skipAddingToPanel={true}
                    xlsImportEnabled={true}
                    onMailsImport={handleImportMailsFromExcelFile}
                />

                <SurveyReceiverList receivers={newUsers} onRemoveReceiver={handleRemoveReceiver} />

                <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={handleClearReceivers} disabled={newUsers.length === 0}>
                        {t('dashboard.sidebar.documents.newDocument.button.cancel')}
                    </CommonButton>
                    <CommonButton btnType={ButtonTypeEnum.CONFIRM} onClick={handleShare} disabled={newUsers.length === 0}>
                        {t('dashboard.sidebar.documentDetails.button.share')}
                    </CommonButton>
                </CardActions>
            </Card>

            <Box mt={2}>
                <Box>
                    <Typography variant="h6">{t('survey.SurveyUsersPage.surveyUsers.header.title')}</Typography>
                    <Typography variant="caption">{t('survey.SurveyUsersPage.surveyUsers.header.description')}</Typography>
                </Box>
                <Box>
                    <FormControl variant="outlined" size="small" fullWidth sx={{ my: 2 }}>
                        <InputLabel htmlFor="outlined-adornment-search">{t('search')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-search"
                            type="text"
                            value={searchValue}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setSearchValue(event.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setSearchValue('')} edge="end">
                                        <Close />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={t('search')}
                        />
                    </FormControl>
                </Box>
                {isLoadingSurveyUsers ? (
                    <SkeletonBlock />
                ) : filteredSurveyUsers && filteredSurveyUsers.length > 0 ? (
                    filteredSurveyUsers.map((surveyUser: SurveyUserAdmin) => (
                        <Card key={surveyUser.uuid} sx={{ mt: 1 }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        {surveyUser.submitted && <CheckCircle color="success" sx={{ mr: 2 }} />}
                                        <Typography variant="caption">{surveyUser.sharedWith ? userLabel(surveyUser.sharedWith) : 'Unknown'}</Typography>
                                    </Box>

                                    <Box>
                                        {surveyUser.submitted && (
                                            <CommonButton btnType={ButtonTypeEnum.DETAILS} onClick={() => handleSurveyUserClick(surveyUser.uuid)}>
                                                {t('survey.SurveyUsersPage.surveyUsers.card.button.view')}
                                            </CommonButton>
                                        )}
                                        <CommonButton btnType={ButtonTypeEnum.DELETE} onClick={() => handleSurveyUserDelete(surveyUser.uuid)} sx={{ ml: 2 }}>
                                            {t('survey.SurveyUsersPage.surveyUsers.card.button.delete')}
                                        </CommonButton>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <EmptyState message={t('survey.SurveyUsersPage.emptyState.message')} icon={<QuestionAnswer />} />
                )}
            </Box>
            <SurveyPreviewDialog onClose={handleClosePreviewDialog} open={previewDialogOpened} pdfBase64={pdfBase64} isLoadingPdf={isLoadingPdf} />
            <ConfirmationDialog
                title={t('survey.SurveyUsersPage.confirmationDialog.title')}
                message={t('survey.SurveyUsersPage.confirmationDialog.message')}
                onConfirm={handleDeleteSurvey}
                onCancel={handleCloseDeleteSurvey}
                open={deleteSurveyDialogOpened}
            />
        </Page>
    );
};

export default SurveyUsersPage;
