import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import {
    FormField,
    OptionRequest,
    OptionRequestIdyWalletFieldEnum,
    OptionRequestTypeEnum,
    Question,
    QuestionTypeEnum
} from 'survey-service-api';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../shared/CommonButton';
import { IdyWalletField, IdyWalletFields } from '../../../common/constants';
import { useAppSelector } from '../../../store/hooks';

interface IAddOptionDialogProps {
    onClose: (request?: OptionRequest) => void;
    open: boolean;
    question: Question;
    hasPdfTemplate?: boolean;
}

const options = [OptionRequestTypeEnum.Input, OptionRequestTypeEnum.Checkbox, OptionRequestTypeEnum.Radio];

const AddOptionDialog: FC<IAddOptionDialogProps> = (props) => {
    const { onClose, open, question, hasPdfTemplate } = props;
    const { t } = useTranslation();

    const { survey } = useAppSelector((state) => state.survey);

    const [request, setRequest] = useState<OptionRequest>({
        label: '',
        type: OptionRequestTypeEnum.Input,
        idyWalletField: question.idyWalletQuestion ? OptionRequestIdyWalletFieldEnum.FullName : undefined,
    });

    useEffect(() => {
        if (question) {
            setRequest({
                label: '',
                type: matchQuestionType(question.type),
                idyWalletField: question.idyWalletQuestion ? OptionRequestIdyWalletFieldEnum.FullName : undefined,
            });
        }
    }, [question]);

    const matchQuestionType = (questionType: QuestionTypeEnum): OptionRequestTypeEnum => {
        const matchingOption = options.find((value) => value.valueOf() === questionType.valueOf());

        return matchingOption || OptionRequestTypeEnum.Input;
    };

    const radioGroupRef = useRef<HTMLElement>(null);

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(request);
        setRequest({
            label: '',
            type: OptionRequestTypeEnum.Input,
            idyWalletField: question.idyWalletQuestion ? OptionRequestIdyWalletFieldEnum.FullName : undefined,
        });
    };

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            label: (event.target as HTMLInputElement).value,
        });
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (open && (e.code === 'Enter' || e.key === 'Enter')) {
            onClose(request);
            setRequest({
                label: '',
                type: OptionRequestTypeEnum.Input,
                idyWalletField: question.idyWalletQuestion ? OptionRequestIdyWalletFieldEnum.FullName : undefined,
            });
        }
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({
            ...request,
            type: (event.target as HTMLInputElement).value as OptionRequestTypeEnum,
        });
    };

    const handleChangeIdyWalletField = (event: SelectChangeEvent) => {
        const walletField = event.target.value as OptionRequestIdyWalletFieldEnum;
        const index = IdyWalletFields.findIndex((value) => value.field === walletField);
        const label = t(IdyWalletFields[index].displayName);
        if (walletField && label) {
            setRequest({
                ...request,
                idyWalletField: walletField,
                label: label,
                type: OptionRequestTypeEnum.Input,
            });
        }
    };

    const handleChangeFormFieldTag = (event: SelectChangeEvent) => {
        setRequest({
            ...request,
            optionTagId: (event.target as HTMLInputElement).value,
        });
    };

    return (
        <Dialog open={open} onClose={() => onClose()} onKeyUp={handleKeyUp} fullWidth>
            <DialogTitle>{t('survey.AddOptionDialog.title')}</DialogTitle>
            <DialogContent dividers>
                {question.idyWalletQuestion ? (
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="idy-wallet-field-select-label">{t('AddOptionDialog.IdyWalletField.label')}</InputLabel>
                            <Select id="field-select" autoWidth fullWidth value={request.idyWalletField} label={t('survey.AddOptionDialog.dialogActions.select.label.field')} onChange={handleChangeIdyWalletField}>
                                {IdyWalletFields.map((idyWalletField: IdyWalletField) => (
                                    <MenuItem value={idyWalletField.field} key={idyWalletField.field}>
                                        {t(idyWalletField.displayName)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                ) : (
                    <Box>
                        <Box>
                            <TextField
                                autoFocus
                                fullWidth
                                placeholder={t('survey.AddOptionDialog.fieldName')}
                                value={request.label}
                                onChange={handleValueChange}
                            />
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Typography variant="caption">{t('survey.AddOptionDialog.radioGroupDesc')}</Typography>
                            <RadioGroup ref={radioGroupRef} value={request?.type} onChange={handleTypeChange}>
                                {options.map((option) => (
                                    <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                                ))}
                            </RadioGroup>
                        </Box>
                    </Box>
                )}

                <Box mt={4}>
                    {hasPdfTemplate && survey && (
                        <FormControl fullWidth>
                            <InputLabel id="form-tag-id">{t('survey.AddOptionDialog.optionTagId.placeholder')}</InputLabel>
                            <Select
                                variant={'outlined'}
                                id="form-field-select"
                                autoWidth
                                fullWidth
                                value={request.optionTagId}
                                label={t('survey.AddOptionDialog.optionTagId.placeholder')}
                                onChange={handleChangeFormFieldTag}>
                                {survey.formFields?.map((formField: FormField) => (
                                    <MenuItem value={formField.name} key={formField.uuid}>
                                        {formField.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>

            </DialogContent>
            <DialogActions>
                <CommonButton btnType={ButtonTypeEnum.CANCEL} onClick={handleCancel}>
                    {t('survey.AddOptionDialog.dialogActions.button.cancel')}
                </CommonButton>
                <CommonButton btnType={ButtonTypeEnum.ADD} onClick={handleOk}>
                    {t('survey.AddOptionDialog.dialogActions.button.add')}
                </CommonButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddOptionDialog;
