import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Page from '../../components/shared/Page';
import { Box, Button, Chip, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
    addOption,
    addQuestion,
    addSection,
    deleteOption,
    deletePlaceholder,
    deleteQuestion,
    generatePreviewPdf,
    getSurvey
} from '../../slices/survey';
import { Option, OptionRequest, Question, QuestionRequest, Section, SectionRequest } from 'survey-service-api';
import AddQuestionDialog from '../../components/survey/edit/AddQuestionDialog';
import AddOptionDialog from '../../components/survey/edit/AddOptionDialog';
import { Add, Close, DeleteForever } from '@mui/icons-material';
import AddSectionDialog from '../../components/survey/edit/AddSectionDialog';
import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {GroupRoutes, PrivateRoutes} from '../../routes';
import SurveyPreviewDialog from '../../components/survey/SurveyPreviewDialog';

const EditSurveyPage: FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { surveyUuid, groupUuid } = params;

    const { survey, pdfBase64, isLoadingPdf } = useAppSelector((state) => state.survey);

    const [addQuestionOpened, setAddQuestionOpened] = useState<boolean>(false);
    const [addOptionOpened, setAddOptionOpened] = useState<boolean>(false);
    const [editingPlaceholderUuid, setEditingPlaceholderUuid] = useState<string | undefined>();
    const [editingQuestion, setEditingQuestion] = useState<Question | undefined>();
    const [newQuestionOrdinal, setNewQuestionOrdinal] = useState<number>(1);
    const [newOptionOrdinal, setNewOptionOrdinal] = useState<number>(1);
    const [newSectionOrdinal, setNewSectionOrdinal] = useState<number>(1);
    const [addSectionOpened, setAddSectionOpened] = useState<boolean>(false);
    const [previewDialogOpened, setPreviewDialogOpened] = useState<boolean>(false);

    useEffect(() => {
        if (surveyUuid) {
            dispatch(getSurvey(surveyUuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddSection = (request: SectionRequest) => {
        if (survey && surveyUuid) {
            request.ordinal = newSectionOrdinal;
            dispatch(addSection(surveyUuid, request));
        }
    };

    const editQuestion = (placeholderUuid: string, request: QuestionRequest) => {
        if (placeholderUuid) {
            request.ordinal = newQuestionOrdinal;
            dispatch(addQuestion(placeholderUuid, request));
        }
    };

    const handleAddOptionClick = (placeholderUuid: string, question: Question) => {
        if (placeholderUuid && question) {
            setEditingPlaceholderUuid(placeholderUuid);
            setEditingQuestion(question);
            setNewOptionOrdinal(question.options.length + 1);
            setAddOptionOpened(true);
        }
    };

    const editOption = (placeholderUuid: string, questionUuid: string, request: OptionRequest) => {
        if (questionUuid) {
            request.ordinal = newOptionOrdinal;
            dispatch(addOption(placeholderUuid, questionUuid, request));
        }
    };

    const handleAddOptionClose = (request?: OptionRequest) => {
        setAddOptionOpened(false);
        if (request && editingPlaceholderUuid && editingQuestion) {
            editOption(editingPlaceholderUuid, editingQuestion.uuid, request);
        }
    };

    const handleAddQuestionClick = (section: Section) => {
        if (section) {
            setEditingPlaceholderUuid(section.uuid);
            setNewQuestionOrdinal(section.questions.length + 1);
            setAddQuestionOpened(true);
        }
    };

    const handleAddQuestionClose = (request?: QuestionRequest) => {
        setAddQuestionOpened(false);
        if (request && editingPlaceholderUuid) {
            editQuestion(editingPlaceholderUuid, request);
        }
    };

    const handleDeleteSection = (uuid: string) => {
        dispatch(deletePlaceholder(uuid));
    };

    const handleDeleteQuestion = (questionUuid: string, placeholderUuid: string) => {
        dispatch(deleteQuestion(questionUuid, placeholderUuid));
    };

    const handleAddSectionClick = () => {
        if (survey) {
            setNewSectionOrdinal(survey?.sections.length + 1);
            setAddSectionOpened(true);
        }
    };

    const handleAddSectionClose = (request?: SectionRequest) => {
        if (request) {
            handleAddSection(request);
        }

        setAddSectionOpened(false);
    };

    const handleDeleteOption = (optionUuid: string, questionUuid: string, sectionUuid: string) => {
        dispatch(deleteOption(optionUuid, questionUuid, sectionUuid));
    };

    const handleNavigateToSurveyDetails = () => {
        if (groupUuid && surveyUuid) {
            navigate(GroupRoutes.group_survey_users_results_route.path.replace(':groupUuid', groupUuid ?? '').replace(':surveyUuid', surveyUuid ?? ''));
        } else if (surveyUuid) {
            navigate(PrivateRoutes.private_survey_users_results_route.path.replace(':surveyUuid', surveyUuid ?? ''));
        }
    };

    const handlePreviewSurvey = () => {
        if (survey) {
            dispatch(generatePreviewPdf(survey.uuid));
            setPreviewDialogOpened(true);
        }
    };

    const handleClosePreviewDialog = () => {
        setPreviewDialogOpened(false);
    };

    return (
        <Page
            pageTitle={t('survey.EditSurveyPage.title')}
            actionButton={<CommonButton btnType={ButtonTypeEnum.BACK} onClick={handleNavigateToSurveyDetails} />}>
            <Box>
                {survey && <Box>{survey.name}</Box>}
                <Typography variant="caption">{t('survey.EditSurveyPage.header.description')}</Typography>
                <Button variant={'outlined'} sx={{ m: 2 }} onClick={handlePreviewSurvey}>
                    {t('survey.SurveyUsersPage.button.surveyPreview.preview')}
                </Button>
                {survey?.templatePdfBase64 && <Chip color="warning" size="small" label={t('EditSurveyPage.chip.warningInfo')} />}
                {survey &&
                    survey.sections &&
                    survey.sections.map((section: Section) => (
                        <Box key={section.uuid} sx={{ border: '2px dashed gray' }} m={1} p={1}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box>
                                    <Box>
                                        <Chip color="success" size="small" label={t('survey.EditSurveyPage.section.chip')} />
                                    </Box>
                                    <Box>
                                        <Typography variant="h6">
                                            {section.ordinal}. {section.title}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="body2">{section.description}</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <IconButton color="error" onClick={() => handleDeleteSection(section.uuid)}>
                                        <DeleteForever />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Box>
                                {section.questions &&
                                    section.questions.map((question: Question) => (
                                        <Box key={question.uuid} sx={{ border: '1px dashed lightgray' }} m={1} p={2}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Box m={1}>
                                                    <Chip color="secondary" size="small" label={t('survey.EditSurveyPage.question.chip')} />
                                                    <Chip color="info" size="small" label={question.type} sx={{ ml: 1 }} />
                                                    {question.idyWalletQuestion && (
                                                        <Chip color="error" size="small" label={t('AddOptionDialog.IdyWalletField.label')} sx={{ ml: 1 }} />
                                                    )}
                                                </Box>
                                                <Box>
                                                    <IconButton color="error" onClick={() => handleDeleteQuestion(question.uuid, section.uuid)}>
                                                        <DeleteForever />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">
                                                    {question.ordinal}. {question.questionText}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Box p={3}>
                                                    {question.options &&
                                                        question.options.map((option: Option) => (
                                                            <Box key={option.uuid} display="flex" alignItems="center">
                                                                <Box>
                                                                    <Typography variant="caption">{option.label}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Chip color="info" size="small" label={option.type} sx={{ ml: 1 }} />
                                                                </Box>
                                                                <Box>
                                                                    {option.optionTagId && (
                                                                        <Chip
                                                                            color="warning"
                                                                            size="small"
                                                                            label={`Tag ID: ${option.optionTagId}`}
                                                                            sx={{ ml: 1 }}
                                                                        />
                                                                    )}
                                                                </Box>
                                                                <Box>
                                                                    {option.idyWalletField && (
                                                                        <Chip
                                                                            color="error"
                                                                            size="small"
                                                                            label={`WalletField: ${option.idyWalletField}`}
                                                                            sx={{ ml: 1 }}
                                                                        />
                                                                    )}
                                                                </Box>
                                                                <Box>
                                                                    <IconButton
                                                                        color="error"
                                                                        size="small"
                                                                        onClick={() => handleDeleteOption(option.uuid, question.uuid, section.uuid)}>
                                                                        <Close fontSize="small" />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                </Box>

                                                <CommonButton
                                                    btnType={ButtonTypeEnum.ADD}
                                                    sx={{ my: 2 }}
                                                    onClick={() => handleAddOptionClick(section.uuid, question)}
                                                    size="small">
                                                    {t('survey.EditSurveyPage.option.button.add')}
                                                </CommonButton>
                                            </Box>
                                        </Box>
                                    ))}
                                <CommonButton
                                    btnType={ButtonTypeEnum.ADD}
                                    sx={{ my: 2 }}
                                    onClick={() => handleAddQuestionClick(section)}
                                    size="small"
                                    color="secondary">
                                    {t('survey.EditSurveyPage.question.button.add')}
                                </CommonButton>
                            </Box>
                        </Box>
                    ))}
                <Button onClick={() => handleAddSectionClick()} size="small" startIcon={<Add />} variant="outlined" color="success" sx={{ ml: 2, my: 2 }}>
                    {t('survey.EditSurveyPage.section.button.add')}
                </Button>
            </Box>
            <AddSectionDialog onClose={handleAddSectionClose} open={addSectionOpened} />
            <AddQuestionDialog onClose={handleAddQuestionClose} open={addQuestionOpened} />
            {editingQuestion && (
                <AddOptionDialog
                    onClose={handleAddOptionClose}
                    open={addOptionOpened}
                    question={editingQuestion}
                    hasPdfTemplate={!!survey?.templatePdfBase64}
                />
            )}
            <SurveyPreviewDialog onClose={handleClosePreviewDialog} open={previewDialogOpened} pdfBase64={pdfBase64} isLoadingPdf={isLoadingPdf} />
        </Page>
    );
};

export default EditSurveyPage;
