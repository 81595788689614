import type { FC } from 'react';
import { Box, Link, Theme, useMediaQuery, useTheme } from '@mui/material';
import signLogoDark from '../../assets/img/illustrations/Identyum-SIGN-logo.svg';
import signLogoLight from '../../assets/img/illustrations/Identyum-SIGN-logo-light.svg';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AppSettings } from '../../common/app-settings';
import useSettings from '../../hooks/useSettings';
import { THEMES } from '../../common/constants';
import { useFooterVisibility } from '../../store/FooterVisibilityContext';

const Footer: FC = () => {
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { settings } = useSettings();
    const isDarkMode = settings.theme === THEMES.DARK;

    const { isVisible } = useFooterVisibility();
    const isSimpleSignRoute = window.location.pathname?.includes('/document-sign');
    const isLogin = window.location.pathname?.includes('/login') || window.location?.pathname === '/';

    if (!isVisible) {
        return null;
    }

    return (
        <Box
            sx={ {
                background: theme.palette.background.paper,
                bottom: 0,
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                marginTop: '16px',
                padding: '16px',
                position: 'fixed',
                width: '100%',
                [theme.breakpoints.up('lg')]: {
                    paddingLeft: (isSimpleSignRoute || isLogin) ? '0' : '280px'
                }
            } }>
            <Box display="flex" alignItems="center" justifyContent="center" mb={ 2 }>
                <img alt="idy_logo" src={ isDarkMode ? signLogoLight : signLogoDark } style={ { height: '26px', marginLeft: '8px', width: 'auto' } } />
            </Box>
            <Box display="flex" justifyContent="center" mb={ 1 }>
                <Link
                    target={ '_blank' }
                    sx={ { '&:hover': { cursor: 'pointer' }, mr: 2, textAlign: 'center' } }
                    color="textSecondary"
                    component={ RouterLink }
                    to={ AppSettings.HELP_CENTER_LINK }
                    variant="caption">
                    { t('layout.footer.label.help') }
                </Link>
                <Link
                    target={ '_blank' }
                    sx={ { '&:hover': { cursor: 'pointer' }, mr: 2, textAlign: 'center' } }
                    color="textSecondary"
                    component={ RouterLink }
                    to={ i18n.language === 'hr' ? AppSettings.TERMS_AND_CONDITIONS_LINK_HR : AppSettings.TERMS_AND_CONDITIONS_LINK_EN }
                    variant="caption">
                    { smUp ? t('layout.footer.label.terms') : t('layout.footer.label.terms.short') }
                </Link>
                <Link
                    target={ '_blank' }
                    sx={ { '&:hover': { cursor: 'pointer' }, mr: 2, textAlign: 'center' } }
                    color="textSecondary"
                    component={ RouterLink }
                    to={ i18n.language === 'hr' ? AppSettings.PRIVACY_POLICY_LINK_HR : AppSettings.PRIVACY_POLICY_LINK_EN }
                    variant="caption">
                    { t('layout.footer.label.privacy') }
                </Link>
                <Link
                    target={ '_blank' }
                    sx={ { '&:hover': { cursor: 'pointer' }, mr: 2, textAlign: 'center' } }
                    color="textSecondary"
                    component={ RouterLink }
                    to={ AppSettings.CONTACT_LINK }
                    variant="caption">
                    { t('layout.footer.label.contact') }
                </Link>
            </Box>
        </Box>
    );
};

export default Footer;
