import type { FC } from 'react';
import { DashboardLayoutProps, UnprotectedRoutesLayoutWrapper } from './CommonLayout';

const UnprotectedRoutesLayout: FC<DashboardLayoutProps> = ({children}) => {
    return (
        <UnprotectedRoutesLayoutWrapper>
            { children }
        </UnprotectedRoutesLayoutWrapper>
    );
};

export default UnprotectedRoutesLayout;
