import {
    Document as UdsDocument,
    DocumentInfo,
    DocumentSignatureTypeEnum,
    EmailShareWith,
    EmailShareWithSignatureTypeEnum,
    GetDocumentsResponse,
    NotifyRecipientsRequest,
    Person,
    Phone,
    PhoneShareWith,
    PhoneShareWithSignatureTypeEnum,
    ShareDocumentsRequest,
    ShareWith,
    SignatureInfo,
    UploadDocumentsRequest
} from 'user-document-signer-api';
import { Document, DocumentInfo as IdyDocumentInfo, Person as IdyPerson, SignatureCoordinates, SignData } from 'idy-certifier-api';
import { ContactType, IDocumentReceiver, IVisualSignatureCoordinates } from '../model/common/documents/document-user';
import deepCopy from './deepCopy';
import { DocumentApiHelper } from './document-api-helper';
import { IOrganizationDocument } from '../model/common/documents/IOrganizationDocument';
import { DocumentIn } from 'organizations-service-api';
import { DocumentResponse, SignData as FinaSignData } from 'fina-lcp-certifier-api';
import { DocumentVisibilityType } from '../model/common/documents/DocumentVisibilityType';
import auth from '../auth/auth';

export class DocumentsHelper {
    public static EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    public static CROATIAN_PHONE_NUMBER_REGEX = /^(0?(91|92|95|97|98|99))([0-9]){6,8}$/;
    public static INTERNATIONAL_PHONE_NUMBER_REGEX = /^[+]?[0-9]{3}[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    public static CROATIA_COUNTRY_CODE = '385';

    public static BYTE = 'byte';
    public static BYTES = 'bytes';
    public static KILOBYTES = 'KB';
    public static MEGABYTES = 'MB';
    public static GIGABYTES = 'GB';

    public static ZERO = 0;
    public static ONE = 1;
    public static KILO = 1024;
    public static MEGA = 1048576;
    public static GIGA = 1073741824;

    public static formatSizeUnits(bytes: number) {
        let result: string;
        if (bytes >= DocumentsHelper.GIGA) {
            result = ( bytes / DocumentsHelper.GIGA ).toFixed(2) + ' ' + DocumentsHelper.GIGABYTES;
        } else if (bytes >= DocumentsHelper.MEGA) {
            result = ( bytes / DocumentsHelper.MEGA ).toFixed(2) + ' ' + DocumentsHelper.MEGABYTES;
        } else if (bytes >= DocumentsHelper.KILO) {
            result = ( bytes / DocumentsHelper.KILO ).toFixed(2) + ' ' + DocumentsHelper.KILOBYTES;
        } else if (bytes > DocumentsHelper.ONE) {
            result = bytes + ' ' + DocumentsHelper.BYTES;
        } else if (bytes == DocumentsHelper.ONE) {
            result = bytes + ' ' + DocumentsHelper.BYTE;
        } else {
            result = DocumentsHelper.ZERO + ' ' + DocumentsHelper.BYTES;
        }
        return result;
    }

    /* eslint-disable no-restricted-properties */
    public static bytesToSize = (bytes: number, decimals = 2): string => {
        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${ parseFloat(( bytes / Math.pow(k, i) ).toFixed(dm)) } ${ sizes[i] }`;
    };

    public static isCroatianMobileNumberFormat(mobileNumber: string): boolean {
        return DocumentsHelper.CROATIAN_PHONE_NUMBER_REGEX.test(DocumentsHelper.stripCroatianCountryCode(mobileNumber));
    }

    public static stripLeadingZeros(mobileNumber: string): string {
        return mobileNumber.replace(/^0*/, '');
    }

    public static isValidInternationalPhoneNumber(mobileNumber: string): boolean {
        return DocumentsHelper.INTERNATIONAL_PHONE_NUMBER_REGEX.test(mobileNumber);
    }

    public static isEmailFormat(email: string): boolean {
        if (!email) return false;
        return DocumentsHelper.EMAIL_REGEX.test(email.trim());
    }

    public static formatToFullCroatianMobileNumber(mobileNumber: string): string {
        let _mobileNumber: string = DocumentsHelper.stripCroatianCountryCode(mobileNumber.replace(/s/g, ''));
        if (_mobileNumber.startsWith('0')) {
            _mobileNumber = _mobileNumber.substring(1);
        }
        return DocumentsHelper.CROATIA_COUNTRY_CODE + _mobileNumber;
    }

    public static stripCroatianCountryCode(mobileNumber: string): string {
        if (mobileNumber.startsWith('385')) {
            return mobileNumber.substring(3);
        }
        if (mobileNumber.startsWith('+385')) {
            return mobileNumber.substring(4);
        }
        if (mobileNumber.startsWith('00385')) {
            return mobileNumber.substring(5);
        }
        return mobileNumber;
    }

    public static getInitials(text?: string): string {
        if (!text) {
            return '';
        }
        const onlyLettersAndNumbers = text
            .replace(/[^A-Za-z0-9\u010D\u0107\u0161\u0111\u017E\u010C\u0106\u0160\u0110\u017D\s]/g, '')
            .replace(/\s\s+/g, ' ')
            .trim();
        const words: string[] = onlyLettersAndNumbers.split(' ') ?? [];

        const firstWord = words?.shift() ?? '';
        const firstWordFirstLetter = firstWord?.charAt(0) ?? '';
        const firstWordSecondLetter = firstWord?.charAt(1) ?? '';

        const lastWordFirstLetter = words?.shift()?.charAt(0) ?? firstWordSecondLetter;

        return firstWordFirstLetter + lastWordFirstLetter ?? '';
    }

    public static fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    public static updateSignedDocumentsIdy(documents: GetDocumentsResponse, signedDocuments: Document[]): GetDocumentsResponse {
        const sharedDocsUuids: string[] = documents?.sharedDocuments?.map((doc) => doc.uuid) ?? [];
        const receivedDocsUuids: string[] = documents?.receivedDocuments?.map((doc) => doc.uuid) ?? [];

        signedDocuments.forEach((doc: Document) => {
            if (sharedDocsUuids.includes(doc.documentInfo.uuid)) {
                const index = documents?.sharedDocuments?.findIndex((x) => x.uuid === doc.documentInfo.uuid);
                if (index !== undefined && index !== -1) {
                    documents.sharedDocuments[index] = deepCopy(doc.documentInfo) as DocumentInfo;
                }
            }
            if (receivedDocsUuids.includes(doc.documentInfo.uuid)) {
                const index = documents?.receivedDocuments?.findIndex((x) => x.uuid === doc.documentInfo.uuid);
                if (index !== undefined && index !== -1) {
                    documents.receivedDocuments[index] = deepCopy(doc.documentInfo) as DocumentInfo;
                }
            }
        });

        return documents;
    }

    public static getDocumentSignerDataUDS(person?: Person): string {
        return person?.fullName
            ? DocumentsHelper.capitalizeEachWord(person.fullName)
            : person?.email
                ? person.email
                : person?.phone
                    ? person?.phone?.dialCode + ' ' + person?.phone?.number
                    : '-';
    }

    public static getDocumentSignerDataIdy(person?: IdyPerson): string {
        return person?.fullName
            ? DocumentsHelper.capitalizeEachWord(person.fullName)
            : person?.email
                ? person.email
                : person?.phone
                    ? person?.phone?.dialCode + ' ' + person?.phone?.number
                    : '-';
    }

    public static getDocumentRecipientIdentifier(person?: Person): string {
        const email: string = person?.email ?? '';
        const phoneNumber: string = person?.phone ? person?.phone?.dialCode + ' ' + person?.phone?.number : '';
        return email + ( email && phoneNumber ? ' / ' : '' ) + phoneNumber;
    }

    public static capitalizeEachWord(text: string): string {
        return text
            ? text
                .trim()
                ?.toLowerCase()
                .split(' ')
                ?.map((w) => w[0]?.toUpperCase() + w?.substring(1))
                ?.join(' ')
            : '';
    }

    public static changeDocumentSharedWith(documents: GetDocumentsResponse, documentInfo: DocumentInfo) {
        const received = documents.receivedDocuments;
        const shared = documents.sharedDocuments;
        let replaceItemIndex = received.findIndex((x) => x.uuid === documentInfo.uuid);
        if (replaceItemIndex !== undefined && replaceItemIndex !== -1) {
            documents.receivedDocuments.splice(replaceItemIndex, 1, documentInfo);
        }

        if (replaceItemIndex === undefined || replaceItemIndex === -1) {
            replaceItemIndex = shared.findIndex((x) => x.uuid === documentInfo.uuid);
            if (replaceItemIndex !== undefined && replaceItemIndex !== -1) {
                documents.sharedDocuments.splice(replaceItemIndex, 1, documentInfo);
            }
        }

        return {
            sharedDocuments: shared,
            receivedDocuments: received
        } as GetDocumentsResponse;
    }

    public static updateSignedDocumentsFina(documents: GetDocumentsResponse, signedDocuments: DocumentResponse[]): GetDocumentsResponse | undefined {
        const sharedDocsUuids: string[] = documents?.sharedDocuments?.map((doc) => doc.uuid) ?? [];
        const receivedDocsUuids: string[] = documents?.receivedDocuments?.map((doc) => doc.uuid) ?? [];

        signedDocuments.forEach((doc: DocumentResponse) => {
            if (sharedDocsUuids.includes(doc.documentInfo.uuid)) {
                const index = documents?.sharedDocuments?.findIndex((x) => x.uuid === doc.documentInfo.uuid);
                if (index !== undefined && index !== -1) {
                    documents.sharedDocuments[index] = deepCopy(doc.documentInfo) as DocumentInfo;
                }
            }
            if (receivedDocsUuids.includes(doc.documentInfo.uuid)) {
                const index = documents?.receivedDocuments?.findIndex((x) => x.uuid === doc.documentInfo.uuid);
                if (index !== undefined && index !== -1) {
                    documents.receivedDocuments[index] = deepCopy(doc.documentInfo) as DocumentInfo;
                }
            }
        });

        return documents;
    }

    public static getShareDocumentsRequestData(
        shareWith: IDocumentReceiver[],
        documentUuid: string,
        signSeparateCopy: boolean,
        groupUuid: string | undefined,
        organizationUuid?: string
    ) {
        const phones: PhoneShareWith[] = getPhones(shareWith);
        const emails: EmailShareWith[] = getEmails(shareWith);
        const documents: string[] = [ documentUuid ];

        const requestData: ShareDocumentsRequest = {
            documents: documents,
            groupUuid: groupUuid,
            organizationUuid: organizationUuid,
            shareWith: {
                phones: [ ...phones ],
                emails: [ ...emails ]
            } as ShareWith,
            signSeparateCopy: signSeparateCopy
        };

        return requestData;
    }

    public static async getUploadDocumentRequestData(
        files: File[],
        groupUuid: string | undefined,
        organizationUserDocuments: IOrganizationDocument[],
        signatureType: DocumentSignatureTypeEnum | undefined,
        downloadableBeforeSignature: boolean
    ): Promise<UploadDocumentsRequest> {
        let documentsForRequest = await DocumentApiHelper.mapFilesToDocuments(files);

        documentsForRequest = documentsForRequest.map((doc) => {
            return {
                content: doc.content.replace('data:application/pdf;base64,', ''),
                downloadableBeforeSignature: downloadableBeforeSignature,
                organizationMemberDocumentsUuidList: organizationUserDocuments.map((doc) => doc.uuid),
                signatureType: signatureType,
                title: deepCopy(doc.title)
            } as UdsDocument;
        });

        return {
            documents: [ ...documentsForRequest ],
            groupUuid: groupUuid
        } as UploadDocumentsRequest;
    }

    public static async getUploadDocumentRequest(files: File[], documentName: string): Promise<DocumentIn[]> {
        const documents = await DocumentsHelper.mapFilesToDocuments(files, documentName);

        return documents.map((doc) => {
            return {
                content: doc.content.replace('data:application/pdf;base64,', ''),
                documentName: doc.documentName,
                fileName: doc.fileName
            } as DocumentIn;
        });
    }

    public static mapFilesToDocuments = async (files: File[], documentName: string): Promise<DocumentIn[]> => {
        const documents: DocumentIn[] = [];
        const base64Files: Promise<string | ArrayBuffer | null>[] = [];

        files.forEach((file) => {
            base64Files.push(DocumentsHelper.fileToBase64(file));
        });

        const base64Resolved = await Promise.all(base64Files);

        files.forEach((file, index) =>
            documents.push({
                content: base64Resolved[index],
                documentName: documentName,
                fileName: file.name
            } as DocumentIn)
        );

        return Promise.resolve(documents);
    };

    public static sortFilterAndPaginateDocuments(documents: DocumentInfo[], query: string, sort: Sort, page: number, limit: number) {
        const filteredDocuments = applyFilters(documents, query);
        const sortedDocuments = applySort(filteredDocuments, sort);
        return applyPagination(sortedDocuments, page, limit);
    }

    public static shouldShowSimpleSignatureCheckbox(groupUuid?: string): boolean {
        if (!groupUuid) {
            return false;
        }

        if (window.location.href && ( window.location.href.includes('dev.identyum.com') || window.location.href.includes('localhost') )) {
            return true;
        }

        const allowedGroupUuids: string[] = [
            '4b215279-848c-40de-9869-5e1fb454e821',
            '5627b266-4234-4ebe-ac06-039e6575d8f8',
            '75e02eb6-9181-42ba-a1b2-0831e46d987d',
            '370e9ae9-8432-4a38-ba85-668e8c556d42',
            '7cfacd3e-5729-4e41-86c0-28c2ecb69e05',
            'd57a760f-9eee-415b-8bf4-fc4ec0903361',
            '5549e8a6-b13a-4e99-b0fa-5f694efd2e25',
            'fadb3948-69cc-46ed-9d33-00f2bd0d246b',
            '5c5dcee0-b90e-46af-87f2-7b38f2e3991b',
            'a4dfc156-f25f-4439-aa46-94fdfe4980c6',
            'fc94456d-0e36-4f5c-8e6f-a2d98f06c9d6',
            '97d24753-9623-482b-9133-9e2ca029d932',
            '49770fbd-477d-4887-9623-6ec204a38aba',
            '79dad624-3b84-444e-a900-680559f94fe1',
            'c71c7e82-2ddc-4c51-8b17-0d5de6b7f1ce',
            '8b548c7f-8ec7-4bf8-b7f3-08bdd639004a',
            '094d0d25-7cb7-42a7-8445-242f6272e879',
            '94318c09-32ba-4da7-aafc-60af01824cc1',
            'ab1104dc-3ebf-4e6c-82cf-997bd4e14c50',
            '488fa25d-1376-452e-a98d-68fcc8b3aac6',
            '5a93cb39-5e5e-430a-9ae3-18b62876054e',
            'bc242041-6363-4171-a625-a4177b082b78',
            '7524bde7-fafd-412e-88b0-e12b82f26571',
            '0546d275-0167-4637-8cf8-a7a5a56916bd',
            'f395c7ad-108f-4f80-a5f5-e9c8ca959f3f',
            '19e2aa0e-aabf-49a6-9c72-57d33520c1fc',
            '0b399104-a33a-4460-a9e4-afce795c9fe5',
            '5e511e9d-8a9c-426b-b79f-7e045cee81f2',
            '8d4026a9-dd25-474f-99a0-9741be4e3c10',
            '67bc0f12-4c0b-4590-9ac6-0094ebe5b198',
            '38365446-3443-486f-8b8d-6f8072cb59bc',
            'a46af0b7-9d8e-4c06-902a-0bc777616490',
            '992ce969-b217-423a-b271-3ae975cbb8ca',
            '7c44db22-cb15-40b6-914c-e697e91fda8b',
            'f26d9a5e-8878-455f-8e60-9a4562879df4',
            'df9a936c-ae0e-43cc-be8c-615a1cc30eda',
            'c8773909-1a8b-46b2-b9f8-541030c7c659',
            'f00e9b01-cb84-4793-a5fa-10f8035283ed',
            '5b477aae-9faa-46eb-a6a5-2612eb294ea0',
            '4ddd2b50-f8cc-4ef4-bf51-086b9f2b4fa5',
            'd86dff9c-c6d9-4ac5-b57d-0f73507c9339',
            '1d080d39-1907-4d4b-8479-a1865bf8d883',
            '74d7d7b1-1035-4b34-bf5d-f703bc77dd51',
            'f212a292-1e15-46c1-ab47-62470bb06a38',
            '35c37386-0fac-4f3e-9c61-3266bff40ff1',
            '3ec307f1-3023-4e07-b5e1-7b4a323ad9f3',
            'eabaff46-2412-4285-9d53-feaae5bbfe04',
            '85e62201-7de4-44ce-8d79-1c2ff4055a8e', // "VEC_destinacija Poreč" - LIVE
            '8a5784dd-7bb1-4e36-9445-c1b37325449e', // "VEC_destinacija Rabac" - LIVE
            'a1931bf1-736e-44dc-8659-1cf254265adc', // "VEC_destinacija Krk" - LIVE
            '6df78c87-c855-4768-9f8d-2e510b3ffa22', // "VEC_destinacija Rab" - LIVE
            'a0299e25-1b54-4c3b-93d4-00e5e2e15351', // "VEC_destinacija Hvar" - LIVE
            'ae83558b-b30a-465d-a891-18116dae74b1', // "VEC_destinacija Makarska" - LIVE
            'ab976583-4f2f-4e12-85d7-a2f226a7198a', // "VEC_destinacija Dubrovnik" - LIVE
            '967fe72a-5c22-45a1-876b-5190039779ba', // "CRM" - LIVE (barbara.marincic-hek@valamar.com)
            '24668343-b110-4653-b92b-eaa17908a9bf', // "Sektor Digitaln Marketing" - LIVE (barbara.marincic-hek@valamar.com aka 385992669966)
            '392fcf0f-c768-4233-ae66-a33521cef1ec', // "CRM" - LIVE
            '7c3b1bc7-92f0-4d7b-8d9f-21985f89f241',  // "IT i e-poslovanje" - LIVE
            '35c37386-0fac-4f3e-9c61-3266bff40ff1',  // MICE ugovori
            'f212a292-1e15-46c1-ab47-62470bb06a38',  // MICE ugovori
            '1d080d39-1907-4d4b-8479-a1865bf8d883',  // MICE ugovori
            'd86dff9c-c6d9-4ac5-b57d-0f73507c9339',  // MICE ugovori
            '4ddd2b50-f8cc-4ef4-bf51-086b9f2b4fa5',  // MICE ugovori
            'eabaff46-2412-4285-9d53-feaae5bbfe04',  // MICE ugovori
            '3ec307f1-3023-4e07-b5e1-7b4a323ad9f3',  // MICE ugovori
            'dda124c3-6f9c-4684-b801-a3b5d4a7774f',  // MICE ugovori
            '0d99ae62-3f92-42cd-8e5b-401b2b0a9eed',  // MICE ugovori
            '6c472322-9aba-4b7e-9e38-2b57b5a02559', //  "test" - LIVE od Valentina.Agapito@valamar.com
        ];

        const kentOrganizationGroupUuids: string[] = [
            '73a7a092-bd6a-4c1e-b0d4-a6f7eab12b9f' //  "Kent organizacijska grupa - Digital" - STAGE
        ];

        const identyumCrewTestingGroups: string[] = [
            '75e02eb6-9181-42ba-a1b2-0831e46d987d', //  "Tomislav i ja" - LIVE
            '7fa74151-a1e4-4b24-9fa0-8c1bb003e240', //  "3 Privatna grupa - link na org" - LIVE
            'b5ea630d-9b39-4de4-aff4-64cfe5fd6b0d', //  "1 Privatna grupa -  link na org" - LIVE
            'd898e6d1-62ee-4de5-94f4-79a03227f026', //  "2 Privatna grupa - link na org" - LIVE
            '422a52a7-e98d-4660-a36f-083f5ce9bac8', //  --> Mislim da je obrisana ova
            '677c0f46-80b6-443e-8530-1d25bea8436f', //  --> Mislim da je obrisana ova
            '7bc6b190-266a-42b0-ae0c-d738cbbe8ae8', //  "PRODAJA (SALES)" - LIVE
            '914dc87f-e016-4b49-acce-cb313368dd15', //  "LJUDSKI RESURSI (HR)" - LIVE
            '939f9d46-be82-4a1b-9eeb-df41546eb9dd', //  "IDENTYUM obračun plaće" - LIVE
            '30b7db62-e8b9-48f5-8e12-78f5faf30a23', //  "Simple sign - owner mabaz" - LIVE
            '8d4026a9-dd25-474f-99a0-9741be4e3c10', //
            'e0387020-f059-4b02-b10f-769efd400675', //  "davaja - owner mabaz" - LIVE
        ];

        return (
            allowedGroupUuids.some((uuid) => uuid === groupUuid) ||
            identyumCrewTestingGroups.some((uuid) => uuid === groupUuid) ||
            kentOrganizationGroupUuids.some((uuid) => uuid === groupUuid)
        );
    }

    public static shouldShowSimpleForOrganization(organizationUuid?: string): boolean {
        if (!organizationUuid) {
            return false;
        }

        const KENT_ORGANIZATION_UUID_STAGE = '7800c406-f04f-463b-9290-b6765ee1a280';

        return KENT_ORGANIZATION_UUID_STAGE === organizationUuid?.trim();
    }

    public static getDocumentReceivers(document: IdyDocumentInfo | undefined): IDocumentReceiver[] {
        if (!document || !document.sharedWith || document.sharedWith.length === 0) {
            return [];
        }

        return document.sharedWith.map((person: IdyPerson) => {
            return {
                contactType: !person.email ? ContactType.MOBILE_NUMBER : ContactType.EMAIL,
                email: person.email,
                phone: person.phone,
                signatureRequested: person.signatureInfo?.signatureRequested
            } as IDocumentReceiver;
        });
    }

    public static changeDocumentVisibility(document: IdyDocumentInfo, documentUuid: string, userUuid: string, isVisible: boolean): IdyDocumentInfo {
        if (documentUuid !== document.uuid) {
            return document;
        }

        if (document.owner?.uuid === userUuid) {
            document.owner.isVisibleDocument = isVisible;
        }

        const person = document?.sharedWith?.find((person: IdyPerson) => person.uuid === userUuid);
        if (person) {
            person.isVisibleDocument = isVisible;
        }

        return document;
    }

    public static changeUDSDocumentVisibility(document: DocumentInfo, documentUuid: string, userUuid: string, isVisible: boolean): DocumentInfo {
        if (documentUuid !== document.uuid) {
            return document;
        }

        if (document.owner?.uuid === userUuid) {
            document.owner.visibleDocument = isVisible;
        }

        const person = document?.sharedWith?.find((person: Person) => person.uuid === userUuid);
        if (person) {
            person.visibleDocument = isVisible;
        }

        return document;
    }

    public static changeDocumentsVisibility(documents: GetDocumentsResponse, documentUuid: string, userUuid: string, isVisible: boolean): GetDocumentsResponse {
        const sharedDocs = deepCopy(documents.sharedDocuments);
        const receivedDocs = deepCopy(documents.receivedDocuments);
        sharedDocs.map((document: DocumentInfo) => DocumentsHelper.changeUDSDocumentVisibility(document, documentUuid, userUuid, isVisible));
        receivedDocs.map((document: DocumentInfo) => DocumentsHelper.changeUDSDocumentVisibility(document, documentUuid, userUuid, isVisible));
        return {
            sharedDocuments: sharedDocs,
            receivedDocuments: receivedDocs
        } as GetDocumentsResponse;
    }

    public static filterDocumentsByVisibility(
        documents: DocumentInfo[],
        visibility: DocumentVisibilityType,
        userUuid: string | null,
        groupUuid: string | undefined
    ) {
        const checkVisibility = (person: Person | undefined, seekForVisible: boolean, seekForHidden: boolean) => {
            const isVisibleDocumentForPerson = person?.visibleDocument || person?.isVisibleDocument;
            return (isVisibleDocumentForPerson && seekForVisible) || (!isVisibleDocumentForPerson && seekForHidden);
        };

        return documents.filter((document: DocumentInfo) => {
            const seekForVisible = visibility === DocumentVisibilityType.VISIBLE || visibility === DocumentVisibilityType.ALL_DOCS;
            const seekForHidden = visibility === DocumentVisibilityType.HIDDEN || visibility === DocumentVisibilityType.ALL_DOCS;

            const isOwner = document.owner?.uuid === (groupUuid || userUuid);
            if (isOwner) {
                return checkVisibility(document.owner, seekForVisible, seekForHidden);
            }

            return document.sharedWith?.some((person: Person) => person.uuid === (groupUuid || userUuid) && checkVisibility(person, seekForVisible, seekForHidden));
        });
    }

    public static getNotifyRecipientRequest(recipients: Person[], documentUuid: string, groupUuid: string, organizationUuid: string): NotifyRecipientsRequest {
        const phones: Phone[] = [];
        const emails: string[] = [];

        recipients.forEach((person: Person) => {
            if (person.phone) {
                phones.push({
                    dialCode: person.phone.dialCode,
                    number: person.phone.number
                } as Phone);
            }

            if (person.email) {
                emails.push(person.email);
            }
        });

        return {
            documentUuid: documentUuid,
            emails: emails,
            groupUuid: groupUuid,
            organizationUuid: organizationUuid,
            phones: phones
        } as NotifyRecipientsRequest;
    }

    public static isSignedByCurrentUser(document: DocumentInfo): boolean {
        const userUuid = auth.getUserUuid();

        if (!userUuid) {
            return false;
        }

        return document.owner?.uuid === userUuid
            ? document?.owner?.signatureInfo?.signed
            : document.sharedWith?.find((signer: Person) => signer.uuid === userUuid)?.signatureInfo?.signed ?? false;
    }

    public static getSignDatas(documentUuidList: string[], visualCoordinatesForSigner?: IVisualSignatureCoordinates): SignData[] {
        return documentUuidList.map((uuid) => {
            const signData = {
                documentUuid: uuid
            } as SignData;

            if (visualCoordinatesForSigner) {
                signData.signatureCoordinates = {
                    height: visualCoordinatesForSigner.height,
                    page: visualCoordinatesForSigner.pageNumber,
                    width: visualCoordinatesForSigner.width,
                    x: visualCoordinatesForSigner.x,
                    y: visualCoordinatesForSigner.y
                } as SignatureCoordinates;
            }

            return signData;
        });
    }

    public static getFinaSignDatas(documentUuidList: string[], visualCoordinatesForSigner?: IVisualSignatureCoordinates): FinaSignData[] {
        return documentUuidList.map((uuid) => {
            const signData = {
                documentUuid: uuid
            } as FinaSignData;

            if (visualCoordinatesForSigner) {
                signData.signatureCoordinates = {
                    height: visualCoordinatesForSigner.height,
                    page: visualCoordinatesForSigner.pageNumber,
                    width: visualCoordinatesForSigner.width,
                    x: visualCoordinatesForSigner.x,
                    y: visualCoordinatesForSigner.y
                } as SignatureCoordinates;
            }

            return signData;
        });
    }

    public static mapDocumentInfoToUDSDocumentInfo(docDetails: IdyDocumentInfo, udsDocumentInfo: DocumentInfo): DocumentInfo {
        function getSigned(person: Person) {
            return docDetails?.sharedWith?.find(
                (signer: IdyPerson) =>
                    signer.uuid === person.uuid ||
                    ( signer.email !== undefined && signer.email === person.email ) ||
                    ( signer.phone !== undefined && signer.phone?.dialCode === person.phone?.dialCode && signer.phone?.number === person.phone?.number )
            )?.signatureInfo?.signed;
        }

        const newSharedWith: Person[] = udsDocumentInfo?.sharedWith?.map((person: Person) => {
            return {
                ...person,
                signatureInfo: {
                    ...person.signatureInfo,
                    signed: getSigned(person)
                } as SignatureInfo
            } as Person;
        });

        return {
            ...udsDocumentInfo,
            owner: {
                ...udsDocumentInfo.owner,
                signatureInfo: {
                    ...udsDocumentInfo.owner?.signatureInfo,
                    signed: docDetails?.owner?.signatureInfo?.signed
                }
            },
            sharedWith: newSharedWith
        } as DocumentInfo;
    }

    public static mapIdyPersonToUdsPerson(persons: IdyPerson[]): Person[] {
        if (!persons) {
            return [];
        }

        return persons.map((person) => {
            return {
                email: person.email,
                fullName: person.fullName,
                group: person.isGroup,
                isGroup: person.isGroup,
                isVisibleDocument: person.isVisibleDocument,
                phone: person.phone,
                signatureInfo: {
                    signed: person.signatureInfo?.signed,
                    signatureRequested: person.signatureInfo?.signatureRequested,
                    signatureType: person.signatureInfo?.signatureType
                } as SignatureInfo,
                uuid: person.uuid,
                visibleDocument: person.isVisibleDocument
            } as Person;
        });

    }
}

export type Sort = 'updatedAt|desc' | 'updatedAt|asc' | 'createdAt|desc' | 'createdAt|asc';

interface SortOption {
    value: Sort;
    label: string;
}

export const sortOptions: SortOption[] = [
    {
        label: 'dashboard.sidebar.documents.list.sort.lastUpdate.newest',
        value: 'updatedAt|desc'
    },
    {
        label: 'dashboard.sidebar.documents.list.sort.lastUpdate.oldest',
        value: 'updatedAt|asc'
    },
    {
        label: 'dashboard.sidebar.documents.list.sort.created.newest',
        value: 'createdAt|desc'
    },
    {
        label: 'dashboard.sidebar.documents.list.sort.created.oldest',
        value: 'createdAt|asc'
    }
];

export const applyFilters = (documents: DocumentInfo[], query: string): DocumentInfo[] =>
    documents.filter((document) => {
        let matches = true;

        if (query) {
            let containsQuery = false;

            if (document.uuid?.toLowerCase()?.includes(query?.toLowerCase()) || document.title?.defaultText?.toLowerCase()?.includes(query?.toLowerCase())) {
                containsQuery = true;
            }

            if (isQueryContainedInPersonEmailOrPhoneNumber(query, document.owner)) {
                containsQuery = true;
            }

            if (document.sharedWith?.some((person: Person) => isQueryContainedInPersonEmailOrPhoneNumber(query, person))) {
                containsQuery = true;
            }

            if (!containsQuery) {
                matches = false;
            }
        }

        return matches;
    });

export const isQueryContainedInPersonEmailOrPhoneNumber = (query: string, person?: Person): boolean => {
    return (
        ( person?.email?.toLowerCase().includes(query.toLowerCase()) ||
            person?.phone?.dialCode?.concat(person.phone?.number)?.toLowerCase().includes(query.toLowerCase()) ) ??
        false
    );
};

export const applyPagination = (documents: DocumentInfo[], page: number, limit: number): DocumentInfo[] => {
    return documents.slice(page * limit, page * limit + limit);
};

export const isVisibleForCurrentUserUds = (userName: string | null, document?: DocumentInfo, groupUuid?: string): boolean => {
    if (!document) {
        return false;
    }

    if (groupUuid) {
        return document.owner?.uuid === groupUuid && ( ( document.owner?.visibleDocument || document.owner?.isVisibleDocument ) ?? false );
    }

    return document.owner?.uuid === userName
        ? ( document.owner?.visibleDocument || document.owner?.isVisibleDocument ) ?? false
        : ( document.sharedWith?.find((signer: Person) => signer.uuid === userName)?.visibleDocument ||
            document.sharedWith?.find((signer: Person) => signer.uuid === userName)?.isVisibleDocument ) ??
        false;
};

export const isVisibleForCurrentUserIdy = (userName: string | null, document?: IdyDocumentInfo, groupUuid?: string): boolean => {
    if (!document) {
        return false;
    }

    if (groupUuid) {
        return document.owner?.uuid === groupUuid && ( ( document.owner?.isVisibleDocument || document.owner?.isVisibleDocument ) ?? false );
    }

    return document.owner?.uuid === userName
        ? ( document.owner?.isVisibleDocument || document.owner?.isVisibleDocument ) ?? false
        : ( document.sharedWith?.find((signer: IdyPerson) => signer.uuid === userName)?.isVisibleDocument ||
            document.sharedWith?.find((signer: IdyPerson) => signer.uuid === userName)?.isVisibleDocument ) ??
        false;
};

const descendingComparator = (a: DocumentInfo, b: DocumentInfo, orderBy: string): number => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }

    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
    return order === 'desc'
        ? (a: DocumentInfo, b: DocumentInfo) => descendingComparator(a, b, orderBy)
        : (a: DocumentInfo, b: DocumentInfo) => -descendingComparator(a, b, orderBy);
};

export const applySort = (documents: DocumentInfo[], sort: Sort): DocumentInfo[] => {
    const validDocuments = documents.filter((doc) => doc.createdAt && doc.updatedAt);
    const [ orderBy, order ] = sort.split('|') as [ string, 'asc' | 'desc' ];
    const comparator = getComparator(order, orderBy);
    const stabilizedThis = validDocuments.map((el, index) => [ el, index ]);

    stabilizedThis.sort((a, b) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newOrder = comparator(a[0], b[0]);

        if (newOrder !== 0) {
            return newOrder;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return a[1] - b[1];
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const documentsWithoutDate = documents.filter((doc) => !doc.updatedAt || !doc.createdAt);
    return stabilizedThis.map((el) => el[0]).concat(documentsWithoutDate) as DocumentInfo[];
};

const getPhones = (shareWith: IDocumentReceiver[]) => {
    return (
        shareWith
            .map((user) => {
                if (user.phone?.number) {
                    const phoneNumber = DocumentsHelper.stripCroatianCountryCode(user.phone?.number);
                    return {
                        apiPhone: {
                            dialCode: user.phone?.dialCode,
                            number: phoneNumber.startsWith('0') ? DocumentsHelper.stripLeadingZeros(phoneNumber) : phoneNumber
                        },
                        signatureRequested: user.signatureRequested ?? true,
                        signatureType: PhoneShareWithSignatureTypeEnum.IdyCertificate
                    } as PhoneShareWith;
                }
                return {} as PhoneShareWith;
            })
            .filter(
                (phone) => phone?.apiPhone?.dialCode && phone?.apiPhone?.dialCode?.length > 0 && phone?.apiPhone?.number && phone?.apiPhone?.number?.length > 0
            ) ?? []
    );
};

const getEmails = (shareWith: IDocumentReceiver[]) => {
    return (
        shareWith
            .map((user) => {
                return {
                    email: user.email ?? '',
                    signatureRequested: user.signatureRequested ?? true,
                    signatureType: EmailShareWithSignatureTypeEnum.IdyCertificate
                } as EmailShareWith;
            })
            .filter((emails) => emails?.email?.trim().length > 0) ?? []
    );
};
