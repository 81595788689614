import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Alert,
    AlertTitle, AppBar,
    Box,
    Card,
    CardContent,
    Container, IconButton,
    InputAdornment,
    TextField, Toolbar, Tooltip,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { checkPin, setAttempts } from 'slices/pin-manager';
import CommonButton, { ButtonTypeEnum } from '../../components/shared/CommonButton';
import auth from '../../auth/auth';
import { useNavigate } from 'react-router-dom';
import { Logout } from '@mui/icons-material';

const PinInsertionPage: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [ userPin, setUserPin ] = useState<string>('');
    const { error, remainingAttempts, isLoading } = useAppSelector((state) => state.pinManager);
    const PIN_LENGTH = 6;

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setUserPin(event.target.value);
    };

    useEffect(() => {
        if (remainingAttempts == 0) {
            dispatch(setAttempts());
            auth.clearLoginData();
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ remainingAttempts ]);

    const handleConfirm = (): void => {
        dispatch(checkPin(userPin));
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            dispatch(checkPin(userPin));
        }
    };

    const isButtonDisabled = () => {
        return PIN_LENGTH - userPin.length !== 0;
    };

    const handleLogout = () => {
        auth.clearLoginData();
        window.location.href = '/login';
    };

    return (
        <>
            <Box
                sx={ {
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                } }>
                <AppBar position="static" sx={{ backgroundColor: 'background.paper' }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <Tooltip title={t('accountPopover.logout')}>
                            <IconButton edge="end" onClick={ handleLogout }>
                                <Logout />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Container maxWidth='sm' sx={ { py: '80px' } }>
                    <Card>
                        <CardContent sx={ { display: 'flex', flexDirection: 'column', p: 4 } }>
                            <Box sx={ { p: 3 } }>
                                <Typography align='center' color='textPrimary' gutterBottom variant='h5'>
                                    { t('dashboard.sidebar.documents.pinPage.title') }
                                </Typography>
                                <Typography align='center' color='textSecondary' variant='body1'>
                                    { t('dashboard.sidebar.documents.pinModal.subtitle') }
                                </Typography>
                                <Typography align='center' color='error' variant='body1' fontWeight={ 500 }
                                            sx={ { m: 2 } }>
                                    { error?.errorMessage ? t(error?.errorMessage) : '' }
                                </Typography>
                                <Box sx={ { mt: 3, textAlign: 'center' } }>
                                    <TextField
                                        error={ Boolean(error?.errorMessage) }
                                        type='password'
                                        autoFocus
                                        inputProps={ { maxLength: 6 } }
                                        fullWidth
                                        helperText={
                                            remainingAttempts
                                                ? t('dashboard.sidebar.documents.pinModal.textField.remaining.attempts', {
                                                    remainingAttempts: `${ remainingAttempts }`
                                                })
                                                : ''
                                        }
                                        label={ 'PIN' }
                                        onChange={ handleChange }
                                        onKeyDown={ keyDownHandler }
                                        placeholder={ t('dashboard.sidebar.documents.pinModal.textField.title') }
                                        rows={ 1 }
                                        value={ userPin }
                                        variant='outlined'
                                        InputProps={ {
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    { userPin.length } / { PIN_LENGTH }
                                                </InputAdornment>
                                            )
                                        } }
                                    />
                                    { remainingAttempts && remainingAttempts <= 3 && (
                                        <Alert severity='warning' sx={ { mt: 2 } }>
                                            <AlertTitle
                                                sx={ { textAlign: 'left' } }>{ t('dashboard.sidebar.documents.pinModal.alert.title') }</AlertTitle>
                                            { t('dashboard.sidebar.documents.pinModal.alert.attempts') }
                                        </Alert>
                                    ) }
                                </Box>
                                <Box
                                    sx={ {
                                        mt: 3,
                                        p: 3
                                    } }>
                                    <CommonButton
                                        btnType={ ButtonTypeEnum.NO_ICON_CONTAINED }
                                        loading={ isLoading }
                                        fullWidth={ true }
                                        onClick={ handleConfirm }
                                        disabled={ isButtonDisabled() || isLoading }>
                                        { t('dashboard.sidebar.documents.pinPage.button.title') }
                                    </CommonButton>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default PinInsertionPage;
