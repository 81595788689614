import Cookies from 'universal-cookie';
import { AppSettings } from '../common/app-settings';
import auth, { Token } from '../auth/auth';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import { getAccessToken } from '../auth/idyAxios';

export interface ICustomJwtPayload extends JwtPayload {
    family_name: string;
    given_name: string;
    preferred_username: string;
    realm_access: {
        roles: string[]
    };
}

export class TokenHelper {

    public static checkForPin(): string {
        const userPin = auth.getUserPin();
        if (userPin && userPin.trim()) {
            return userPin;
        }

        const cookies = new Cookies();
        const pinFromCookie = cookies.get(AppSettings.USER_PIN);

        if (pinFromCookie) {
            auth.setUserPin(pinFromCookie);
            cookies.remove(AppSettings.USER_PIN);
        }

        return auth.getUserPin() ?? '';
    }

    public static getUserRoles(): string[] {
        const token = getAccessToken();
        if (!token) return [];

        const decodedToken: ICustomJwtPayload = jwt_decode(token?.access_token ?? '');
        if (!decodedToken.exp) return [];

        return decodedToken.realm_access.roles;
    }

    public static isTokenExpirationDateValid(): boolean {
        const token: Token | null = getAccessToken();
        if (!token) return false;

        const accessToken: ICustomJwtPayload = jwtDecode(token?.access_token ?? '');
        const refreshToken: ICustomJwtPayload = jwtDecode(token?.refresh_token ?? '');

        if (!accessToken.exp) return false;
        if (!refreshToken.exp) return false;

        const accessTokenExpirationDate = new Date(accessToken.exp * 1000);
        const refreshTokenExpirationDate = new Date(refreshToken.exp * 1000);

        return this.isAfterNow(accessTokenExpirationDate) || this.isAfterNow(refreshTokenExpirationDate);
    }

    private static isAfterNow(date: Date): boolean {
        return date.getTime() > new Date().getTime();
    }
}
