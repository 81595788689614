import { AppThunk } from '../store';
import apis from '../auth/apis';
import { handleError } from './errors-handling';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Answer,
    AnswerRequest,
    Option,
    OptionRequest,
    Pdf,
    Question,
    QuestionRequest,
    Section,
    SectionRequest,
    SurveyAdmin, SurveyFromExisting,
    SurveyRequest,
    SurveyShareRequest,
    SurveyUser,
    SurveyUserAdmin,
    UserData,
    WalletData,
    WalletDataRequest,
} from 'survey-service-api';
import { toast } from 'react-hot-toast';
import auth from '../auth/auth';

interface ISurveyState {
    createdSurveys: SurveyAdmin[];
    idyWalletData: WalletData | undefined;
    isLoading: boolean;
    isLoadingPdf: boolean;
    isLoadingSurveyUsers: boolean;
    isSubmittingIdyWalletAnswers: boolean;
    isSubmittingSurvey: boolean;
    pdfBase64: string | undefined;
    sharedSurvey: SurveyUser | undefined;
    sharedSurveys: SurveyUser[];
    submittedIdyWalletAnswers: Answer[];
    submittedSurvey: SurveyUserAdmin | undefined;
    sharedSurveyUsers: SurveyUserAdmin[];
    survey: SurveyAdmin | undefined;
    surveyInitiatorData: UserData | undefined;
}

const initialState: ISurveyState = {
    createdSurveys: [],
    idyWalletData: undefined,
    isLoading: false,
    isLoadingPdf: false,
    isLoadingSurveyUsers: false,
    isSubmittingIdyWalletAnswers: false,
    isSubmittingSurvey: false,
    pdfBase64: undefined,
    sharedSurvey: undefined,
    sharedSurveys: [],
    sharedSurveyUsers: [],
    submittedIdyWalletAnswers: [],
    submittedSurvey: undefined,
    survey: undefined,
    surveyInitiatorData: undefined,
};

const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        addNewSurvey(state: ISurveyState, action: PayloadAction<SurveyAdmin>) {
            state.createdSurveys.push(action.payload);
            state.isLoading = false;
        },
        deleteOption(state: ISurveyState, action: PayloadAction<{ optionUuid: string; questionUuid: string; sectionUuid: string }>) {
            if (state.survey) {
                const index = state.survey.sections.findIndex((value) => value.uuid === action.payload.sectionUuid);
                if (index >= 0) {
                    const questions = state.survey.sections[index].questions;
                    const questionIndex = questions.findIndex((value) => value.uuid === action.payload.questionUuid);
                    if (questionIndex >= 0) {
                        const options = questions[questionIndex].options;
                        const optionIndex = options.findIndex((value) => value.uuid === action.payload.optionUuid);
                        if (optionIndex >= 0) {
                            state.survey.sections[index].questions[questionIndex].options.splice(optionIndex, 1);
                        }
                    }
                }
            }
        },
        deletePlaceholder(state: ISurveyState, action: PayloadAction<string>) {
            if (state.survey) {
                const index = state.survey.sections.findIndex((value) => value.uuid === action.payload);
                if (index >= 0) {
                    state.survey.sections.splice(index, 1);
                }
            }
        },
        deleteQuestion(state: ISurveyState, action: PayloadAction<{ questionUuid: string; placeholderUuid: string }>) {
            if (state.survey) {
                const index = state.survey.sections.findIndex((value) => value.uuid === action.payload.placeholderUuid);
                if (index >= 0) {
                    const questions = state.survey.sections[index].questions;

                    const questionIndex = questions.findIndex((value) => value.uuid === action.payload.questionUuid);
                    if (questionIndex >= 0) {
                        questions.splice(questionIndex, 1);
                    }
                }
            }
        },
        deleteSurvey(state: ISurveyState, action: PayloadAction<string>) {
            const index = state.createdSurveys.findIndex((value) => value.uuid === action.payload);
            if (index >= 0) {
                state.createdSurveys.splice(index, 1);
            }
        },
        deleteSurveyUser(state: ISurveyState, action: PayloadAction<string>) {
            const index = state.sharedSurveyUsers.findIndex((value) => value.uuid === action.payload);
            if (index >= 0) {
                state.sharedSurveyUsers.splice(index, 1);
            }
        },
        setCreatedSurveys(state: ISurveyState, action: PayloadAction<SurveyAdmin[]>) {
            state.createdSurveys = action.payload as SurveyAdmin[];
            state.isLoading = false;
        },
        setIdyWalletData(state: ISurveyState, action: PayloadAction<WalletData>) {
            state.idyWalletData = action.payload as WalletData;
            state.isLoading = false;
        },
        setIsLoading(state: ISurveyState, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
        setIsLoadingPdf(state: ISurveyState, action: PayloadAction<boolean>): void {
            state.isLoadingPdf = action.payload;
        },
        setIsLoadingSurveyUsers(state: ISurveyState, action: PayloadAction<boolean>): void {
            state.isLoadingSurveyUsers = action.payload;
        },
        setIsSubmittingIdyWalletAnswers(state: ISurveyState, action: PayloadAction<boolean>) {
            state.isSubmittingIdyWalletAnswers = action.payload;
        },
        setIsSubmittingSurvey(state: ISurveyState, action: PayloadAction<boolean>) {
            state.isSubmittingSurvey = action.payload;
        },
        setSharedSurvey(state: ISurveyState, action: PayloadAction<SurveyUser>) {
            state.sharedSurvey = action.payload as SurveyUser;
            state.isLoading = false;
        },
        setSharedSurveyAsSubmitted(state: ISurveyState) {
            const sharedSurveyUpdated = state.sharedSurvey;
            if (sharedSurveyUpdated) {
                sharedSurveyUpdated.submitted = true;
            }
            state.sharedSurvey = sharedSurveyUpdated;
        },
        setSharedSurveys(state: ISurveyState, action: PayloadAction<SurveyUser[]>) {
            state.sharedSurveys = action.payload as SurveyUser[];
            state.isLoading = false;
        },
        setSharedSurveyUsers(state: ISurveyState, action: PayloadAction<SurveyUserAdmin[]>) {
            state.sharedSurveyUsers = [
                ...state.sharedSurveyUsers,
                ...action.payload.filter((surveyUser) => surveyUser.sharedWith?.userUuid !== auth.getUserUuid()),
            ];
            state.isLoading = false;
        },
        setSubmittedIdyWalletAnswers(state: ISurveyState, action: PayloadAction<Answer[]>) {
            state.submittedIdyWalletAnswers = action.payload;
        },
        setSubmittedSurvey(state: ISurveyState, action: PayloadAction<SurveyUserAdmin>) {
            state.submittedSurvey = action.payload as SurveyUserAdmin;
            state.isLoading = false;
        },
        setSurvey(state: ISurveyState, action: PayloadAction<SurveyAdmin>) {
            state.survey = action.payload as SurveyAdmin;
            state.isLoading = false;
        },
        setSurveyUserInitiatorData(state: ISurveyState, action: PayloadAction<UserData>) {
            state.surveyInitiatorData = action.payload as UserData;
            state.isLoading = false;
        },
        setSurveyUsers(state: ISurveyState, action: PayloadAction<SurveyUserAdmin[]>) {
            state.sharedSurveyUsers = action.payload as SurveyUserAdmin[];
            state.isLoading = false;
        },
        updateGeneratedPdf(state: ISurveyState, action: PayloadAction<Pdf>) {
            state.pdfBase64 = action.payload.base64;
        },
        updateOptions(state: ISurveyState, action: PayloadAction<{ data: Option; placeholderUuid: string; questionUuid: string }>) {
            if (state.survey && state.survey.sections) {
                const index = state.survey.sections.findIndex((value) => value.uuid === action.payload.placeholderUuid);
                if (index >= 0) {
                    const questions = state.survey.sections[index].questions;
                    if (questions) {
                        const questionIndex = questions.findIndex((value) => value.uuid === action.payload.questionUuid);
                        if (questionIndex >= 0) {
                            questions[questionIndex].options?.push(action.payload.data);
                        }
                    }
                }
            }
        },
        updatePlaceholders(state: ISurveyState, action: PayloadAction<Section>) {
            state.survey?.sections?.push(action.payload);
        },
        updateQuestions(state: ISurveyState, action: PayloadAction<{ data: Question; placeholderUuid: string }>) {
            if (state.survey && state.survey.sections) {
                const index = state.survey.sections.findIndex((value) => value.uuid === action.payload.placeholderUuid);
                if (index >= 0) {
                    state.survey.sections[index].questions?.push(action.payload.data);
                }
            }
        },
        updateSharedSurveyAnswer(state: ISurveyState, action: PayloadAction<Answer>) {
            if (state.sharedSurvey) {
                const answerUuids = state.sharedSurvey.answers.map((value) => value.uuid);
                if (action.payload.uuid && answerUuids) {
                    const index = answerUuids.findIndex((answerUuid) => action.payload.uuid === answerUuid);
                    if (index >= 0) {
                        state.sharedSurvey.answers.splice(index, 1);
                    }
                }
                state.sharedSurvey.answers.push(action.payload);
            }
        },
        updateSurveyUser(state: ISurveyState, action: PayloadAction<SurveyUser>) {
            if (state.sharedSurvey) {
                state.sharedSurvey = action.payload;
            }
        },
    },
});

export const getCreatedSurveys =
    (groupUuid?: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyApi()
            .getSurveys(groupUuid)
            .then((result) => {
                dispatch(surveySlice.actions.setCreatedSurveys(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getMySurveys =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyUsersApi()
            .getUserSurveys()
            .then((result) => {
                dispatch(surveySlice.actions.setSharedSurveys(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getMySurvey =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyUsersApi()
            .getUserSurvey(uuid)
            .then((result) => {
                dispatch(surveySlice.actions.setSharedSurvey(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const addAnswer =
    (surveyUserUuid: string, answer: AnswerRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        // dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyUsersWithoutAuthApi()
            .addAnswer(surveyUserUuid, answer)
            .then((result) => {
                dispatch(surveySlice.actions.updateSharedSurveyAnswer(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const createSurvey =
    (surveyRequest: SurveyRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyApi()
            .addSurvey(surveyRequest)
            .then((result) => {
                dispatch(surveySlice.actions.addNewSurvey(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const createNewFromExisting =
    (request: SurveyFromExisting): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(surveySlice.actions.setIsLoading(true));
            apis.surveyApi()
                .createFromExistingSurvey(request)
                .then(() => {
                    dispatch(surveySlice.actions.setIsLoading(false));
                })
                .catch((err) => {
                    dispatch(surveySlice.actions.setIsLoading(false));
                    dispatch(handleError(err));
                });
        };

export const addSection =
    (surveyUuid: string, section: SectionRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        // dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyApi()
            .addSection(surveyUuid, section)
            .then((result) => {
                dispatch(surveySlice.actions.updatePlaceholders(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getSurvey =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyApi()
            .getSurvey(uuid)
            .then((result) => {
                dispatch(surveySlice.actions.setSurvey(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const addQuestion =
    (sectionUuid: string, question: QuestionRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        // dispatch(surveySlice.actions.setIsLoading(true));
        apis.sectionsApi()
            .addQuestion(sectionUuid, question)
            .then((result) => {
                dispatch(surveySlice.actions.updateQuestions({ data: result.data, placeholderUuid: sectionUuid }));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const addOption =
    (placeholderUuid: string, questionUuid: string, option: OptionRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        // dispatch(surveySlice.actions.setIsLoading(true));
        apis.questionsApi()
            .addOption(questionUuid, option)
            .then((result) => {
                dispatch(surveySlice.actions.updateOptions({ data: result.data, placeholderUuid: placeholderUuid, questionUuid: questionUuid }));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const shareSurvey =
    (uuid: string, request: SurveyShareRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoadingSurveyUsers(true));
        apis.surveyApi()
            .shareSurvey(uuid, request)
            .then((result) => {
                toast.success('Uspješno podijeljeno! ');
                dispatch(surveySlice.actions.setSharedSurveyUsers(result.data));
                dispatch(surveySlice.actions.setIsLoadingSurveyUsers(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const deletePlaceholder =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        // dispatch(surveySlice.actions.setIsLoading(true));
        apis.sectionsApi()
            .deleteSection(uuid)
            .then(() => {
                dispatch(surveySlice.actions.deletePlaceholder(uuid));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const deleteQuestion =
    (questionUuid: string, placeholderUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        // dispatch(surveySlice.actions.setIsLoading(true));
        apis.questionsApi()
            .deleteQuestion(questionUuid)
            .then(() => {
                dispatch(surveySlice.actions.deleteQuestion({ questionUuid: questionUuid, placeholderUuid: placeholderUuid }));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const deleteOption =
    (optionUuid: string, questionUuid: string, sectionUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        // dispatch(surveySlice.actions.setIsLoading(true));
        apis.optionsApi()
            .deleteOption(optionUuid)
            .then(() => {
                dispatch(surveySlice.actions.deleteOption({ optionUuid: optionUuid, questionUuid: questionUuid, sectionUuid: sectionUuid }));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getSharedSurveyUsers =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoadingSurveyUsers(true));
        apis.surveyApi()
            .getSurveyUsers(uuid)
            .then((result) => {
                dispatch(surveySlice.actions.setSurveyUsers(result.data));
                dispatch(surveySlice.actions.setIsLoadingSurveyUsers(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoadingSurveyUsers(false));
                dispatch(handleError(err));
            });
    };

export const getSharedSurveyResult =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyUsersApi()
            .getSharedSurveyResult(uuid)
            .then((result) => {
                dispatch(surveySlice.actions.setSubmittedSurvey(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const submitSurvey =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsSubmittingSurvey(true));
        apis.surveyUsersApi()
            .submitSurvey(uuid)
            .then((result) => {
                dispatch(surveySlice.actions.updateSurveyUser(result.data));
                dispatch(surveySlice.actions.setIsSubmittingSurvey(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsSubmittingSurvey(false));
                dispatch(handleError(err));
            });
    };

export const deleteSurveyUser =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.deleteSurveyUser(uuid));
        apis.surveyUsersApi()
            .deleteSurveyUser(uuid)
            .then()
            .catch((err) => {
                dispatch(handleError(err));
            });
    };

export const deleteSurvey =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        // dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyApi()
            .deleteSurvey(uuid)
            .then(() => {
                dispatch(surveySlice.actions.deleteSurvey(uuid));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const editAnswer =
    (uuid: string, request: AnswerRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        // dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyUsersWithoutAuthApi()
            .editAnswer(uuid, request)
            .then((result) => {
                dispatch(surveySlice.actions.updateSharedSurveyAnswer(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const generatePdf =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoadingPdf(true));
        apis.surveyUsersApi()
            .generatePdf(uuid)
            .then((result) => {
                dispatch(surveySlice.actions.updateGeneratedPdf(result.data));
                dispatch(surveySlice.actions.setIsLoadingPdf(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoadingPdf(false));
                dispatch(handleError(err));
            });
    };

export const generatePreviewPdf =
    (uuid: string): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(surveySlice.actions.setIsLoadingPdf(true));
            apis.surveyApi()
                .generatePreviewPdf(uuid)
                .then((result) => {
                    dispatch(surveySlice.actions.updateGeneratedPdf(result.data));
                    dispatch(surveySlice.actions.setIsLoadingPdf(false));
                })
                .catch((err) => {
                    dispatch(surveySlice.actions.setIsLoadingPdf(false));
                    dispatch(handleError(err));
                });
        };

export const getSurveyUserWithoutAuth =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyUsersWithoutAuthApi()
            .getSurveyUserWithoutAuth(uuid)
            .then((result) => {
                dispatch(surveySlice.actions.setSharedSurvey(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getSurveyUserInitiatorData =
    (uuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyUsersApi()
            .getInitiatorUserData(uuid)
            .then((result) => {
                dispatch(surveySlice.actions.setSurveyUserInitiatorData(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const getIdyWalletData =
    (surveyUserUuid: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsLoading(true));
        apis.surveyUsersApi()
            .getIdyWalletAnswers(surveyUserUuid)
            .then((result) => {
                dispatch(surveySlice.actions.setIdyWalletData(result.data));
                dispatch(surveySlice.actions.setIsLoading(false));
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsLoading(false));
                dispatch(handleError(err));
            });
    };

export const submitIdyWalletAnswers =
    (surveyUserUuid: string, walletDataRequest: WalletDataRequest): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(surveySlice.actions.setIsSubmittingIdyWalletAnswers(true));
        apis.surveyUsersApi()
            .submitIdyWalletAnswers(surveyUserUuid, walletDataRequest)
            .then((result) => {
                dispatch(surveySlice.actions.setSubmittedIdyWalletAnswers(result.data));
                dispatch(surveySlice.actions.setIsSubmittingIdyWalletAnswers(false));
                dispatch(surveySlice.actions.setSharedSurveyAsSubmitted());
            })
            .catch((err) => {
                dispatch(surveySlice.actions.setIsSubmittingIdyWalletAnswers(false));
                dispatch(handleError(err));
            });
    };

export const { reducer } = surveySlice;

export default surveySlice;
