import { FC, ReactNode, useContext, useState, createContext } from 'react';

interface FooterVisibilityContextProps {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
}

export const FooterVisibilityContext = createContext<FooterVisibilityContextProps | undefined>(undefined);

export const FooterVisibilityProvider: FC<{ children: ReactNode; }> = ({ children }) => {
    const [ isVisible, setIsVisible ] = useState(true);

    return (
        <FooterVisibilityContext.Provider value={ { isVisible, setIsVisible } }>
            { children }
        </FooterVisibilityContext.Provider>
    );
};

export const useFooterVisibility = () => {
    const context = useContext(FooterVisibilityContext);
    if (context === undefined) {
        throw new Error('useFooterVisibility must be used within a FooterVisibilityProvider');
    }
    return context;
};
